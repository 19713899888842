import CodeInput from '@/components/Auth/login/CodeInput';
import PasswordInput from '@/components/Auth/login/PasswordInput';
import PhoneInput from '@/components/Auth/login/PhoneInput';
import CommonSkeleton from '@/components/CommonSkeleton';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import services from '@/services';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  onSuccess?: () => void;
  showClose?: boolean;
}

const SetPayPasswordPage: React.FC<Props> = (props: Props) => {
  const [pwd, setPwd] = useState<string>('');
  const [pwdAgain, setPwdAgain] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const navigate = useNavigate();
  const { __ } = useLang();

  const [loading, setLoading] = useState<boolean>(false);

  const { getUserInfo, user } = useAuth();

  const handleSendSMS = (isPhone: boolean) => async () => {
    if (isPhone) {
      return services.auth.sendSMS({ type: 4 });
    } else {
      return services.auth.sendEmail({ type: 4 });
    }
  };

  const handleSubmit = (isPhone: boolean) => async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const params = {
      type: isPhone ? 1 : 2,
      password: pwd,
      password1: pwdAgain,
      code,
    };
    const isSuccess = await services.auth.setPaymentPassword(params);
    if (isSuccess) {
      SnackbarUtils.success(__('Successfully'));
      getUserInfo();
      if (props.onSuccess) {
        props.onSuccess();
      } else {
        navigate('/setting');
      }
    }
    setLoading(false);
  };

  if (!user) {
    return <CommonSkeleton />;
  }

  const isBindPhone = !!user.phone;

  return (
    <Box pb={4}>
      <Box
        sx={{
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 1.5,
        }}
      >
        {props.showClose ? (
          <Box width="40px"></Box>
        ) : (
          <IconButton color="info" onClick={() => navigate('/setting')}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        )}
        <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
          {__('Set Payment Password')}
        </Typography>
        {props.showClose ? (
          <Box width="40px">
            <IconButton color="info" onClick={props.onSuccess}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box width="40px"></Box>
        )}
      </Box>
      <Box px={2}>
        <Stack spacing="20px" mt={2.5}>
          {isBindPhone ? (
            <PhoneInput type="text" phone={user.phone} disabled areaCode={55} />
          ) : (
            <OutlinedInput value={user.email} disabled />
          )}
          <CodeInput
            code={code}
            onChange={(v) => setCode(v)}
            onSendSMS={handleSendSMS(isBindPhone)}
          />
          <PasswordInput
            password={pwd}
            onChangePassword={(pwd) => setPwd(pwd)}
            placeholder={__('Enter new password')}
          />
          <PasswordInput
            password={pwdAgain}
            onChangePassword={(pwd) => setPwdAgain(pwd)}
            placeholder={__('Enter new password Again')}
          />
        </Stack>
        <Typography mt="12px" variant="body2" color="text.disabled">
          {__('set payment tips')}
        </Typography>
        <LoadingButton
          sx={{
            background: 'linear-gradient(90deg, #B304FE 0%, #0CC0F7 100%)',
            boxShadow: 'none !important',
            mt: '20px',
          }}
          fullWidth
          variant="contained"
          loading={loading}
          onClick={handleSubmit(isBindPhone)}
        >
          {__('Submit')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default SetPayPasswordPage;
