export const IconCoupon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="64"
      viewBox="0 0 140 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.58172 3.58172 0 8 0H132C136.418 0 140 3.58172 140 8V26C136.686 26 134 28.6863 134 32C134 35.3137 136.686 38 140 38V56C140 60.4183 136.418 64 132 64H8C3.58172 64 0 60.4183 0 56V38C3.31371 38 6 35.3137 6 32C6 28.6863 3.31371 26 0 26V8Z"
        fill={color}
      />
    </svg>
  );
};
