import services from '@/services';
import { useCallback, useState } from 'react';

export interface InviteRecordDto {
  /**
   * 受邀人充值金额
   */
  rechargeAmt: number;
  /**
   * 受邀人注册时间
   */
  regTime: number;
  /**
   * 受邀人id;
   */
  userId: string;
  /**
   * 打码金额
   */
  orderedAmt: number;
  /**
   * 受邀人激活状态
   */
  inviteeState: number;
}

const SIZE = 20;
export const useInviteRecords = () => {
  const [records, setRecords] = useState<InviteRecordDto[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const hasMore = page < totalPage;

  const getFisrtPage = useCallback(async () => {
    try {
      setLoading(true);
      const response = await services.config.getInviteRecords({
        current: 1,
        size: SIZE,
      });

      setRecords(response?.data);
      setTotalPage(response?.total_page ?? 0);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const loadMore = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    try {
      setLoading(true);
      const response = await services.config.getInviteRecords({
        current: page + 1,
        size: SIZE,
      });

      if (Array.isArray(response?.data)) {
        setRecords((prev) => [...prev, ...response?.data]);
        setPage(page + 1);
      }
      setTotalPage(response?.total_page ?? 0);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [hasMore, page]);

  const reset = () => {
    setRecords([]);
    setPage(1);
    setTotalPage(0);
  };

  return {
    records,
    getFisrtPage,
    hasMore,
    loadMore,
    loading,
    reset,
  };
};
