import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import BOX_REWARD from '@/assets/invitebox/box-reward.png';
import Center from '@/components/center';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  num?: number;
  pic?: string;
}

export const BoxRewardDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box>
        <Box bgcolor="#212933" p={2} borderRadius="24px">
          <Center>
            <img
              src={props.pic ?? BOX_REWARD}
              alt=""
              style={{
                width: 120,
                height: 120,
                // border: '1px solid #333E4F',
                // backgroundColor: 'background.paper',
                // borderRadius: '16px',
              }}
            />
          </Center>
          <Typography textAlign="center" variant="subtitle2" fontWeight={700}>
            {props.title}
          </Typography>
          <Typography
            textAlign="center"
            variant="body2"
            color={'text.secondary'}
            my={1}
          >
            {`A total of`}
            <span style={{ color: 'white' }}> {props.num} </span>
            {`treasure chests opened`}
          </Typography>

          <Button
            variant="contained"
            sx={{ width: '240px' }}
            onClick={props.onClose}
          >
            Receive
          </Button>
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={props.onClose}>
            {/* <CloseRoundedIcon /> */}
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};
