import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenVipRebateState } from '@/stores/auth';
import { globalMaxWidth } from '@/theme';
import { Box, Dialog, SwipeableDrawer } from '@mui/material';
import { useRecoilState } from 'recoil';
import DrawerHeder from './DrawerHeder';
import VipRebateContent from './VipRebateContent';

interface Props {
  lastSign?: number;
}

const VipRebateDrawer = (props: Props) => {
  const { __ } = useLang();
  const [openVipRebate, setOpenVipRebate] =
    useRecoilState(isOpenVipRebateState);

  const isMobile = useResponsive('down', 'md');

  const _onClose = () => {
    setOpenVipRebate(false);
  };

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={openVipRebate}
        onClose={() => {
          setOpenVipRebate(false);
        }}
        onOpen={() => setOpenVipRebate(true)}
        anchor={isMobile ? 'bottom' : 'right'}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
            width: '100%',
            maxWidth: globalMaxWidth,
            left: {
              xs: 0,
              sm: `calc(50% - ${globalMaxWidth / 2}px)`,
            },
          },
        }}
        sx={{ zIndex: 9999 }}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
      >
        <Box bgcolor="card.main" pb={2}>
          <DrawerHeder title={__('Rule description')} onClose={_onClose} />
          <VipRebateContent />
        </Box>
      </SwipeableDrawer>
    );
  }

  return (
    <Dialog
      open={openVipRebate}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
        },
      }}
      onClose={() => {
        setOpenVipRebate(false);
      }}
      aria-labelledby="sign-in"
    >
      <Box bgcolor="card.main" pb={2}>
        <DrawerHeder title={__('Rule description')} onClose={_onClose} />
        <VipRebateContent />
      </Box>
    </Dialog>
  );
};

export default VipRebateDrawer;
