import services from '@/services';
import { ILuckyRewardItem } from '@/types/box';
import { useEffect, useState } from 'react';

export const useWinningRecords = () => {
  const [records, setRecords] = useState<ILuckyRewardItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const hasMore = records.length < total;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const data = await services.lucky.getRewardList({
        page,
        pagesize: 10,
      });

      const { list = [], total = 0 } = data?.data || {};
      setRecords((prev) => {
        return [...prev, ...list];
      });
      setTotal(total);
      setLoading(false);
    };
    fetchData();
  }, [page]);

  const loadMore = () => {
    if (records.length >= total) {
      return;
    }
    setPage(page + 1);
  };

  return {
    records,
    page,
    setPage,
    loadMore,
    total,
    hasMore,
    loading,
  };
};
