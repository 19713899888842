import services from '@/services';

import { testHost } from '@/services/host';
import { isLoginState, supportUrlState } from '@/stores/auth';
import {
  allGamesList,
  allGamesMap,
  bannerList,
  bottomSupportList,
  diaLogBannerList,
  gameCategoryList,
  gameListRecently,
  gameListRecommend,
  incognitoModeIPState,
  indexCategoryList,
  isDataLoadedState,
  minTiXianState,
  payChannelDataState,
  plateformState,
  prominentSupportList,
  supportListState,
  tenantConfigState,
} from '@/stores/config';
import { languageState } from '@/stores/lang';
import { IGameItem, IIndexCategory, IndexCategory } from '@/types/config';
import { Box } from '@mui/material';
import { orderBy } from 'lodash';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useScript } from 'usehooks-ts';

interface IGameMap {
  [id: number]: IGameItem;
}

interface IIndexCategoryMap {
  [key: string]: IndexCategory;
}

const getGameListByCategoryId = (categoryId: number, gameList: IGameItem[]) => {
  return gameList.filter((item) => item.category_id === categoryId);
};

const gameMap2List = (games: IGameMap) => {
  let gameList = [];
  for (const key in games) {
    if (Object.prototype.hasOwnProperty.call(games, key)) {
      gameList.push({ ...games[key] });
    }
  }
  return gameList;
};

const indexCategoryMap2List = (map: IIndexCategoryMap) => {
  let indexCategoryList = [];
  for (const key in map) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
      indexCategoryList.push({
        title: key,
        ids: map[key]?.list || [],
        show_type: map[key].show_type,
        icon: map[key].icon,
        bgImg: map[key].bgImg,
      });
    }
  }
  return indexCategoryList as IIndexCategory[];
};

const iconAddHost = (tenantConfig: any) => {
  let host = testHost;
  let iconConfig = tenantConfig?.iconConfig || {};
  let iconConfigTmp: { [key: string]: { [key: string]: any } } = {};
  let iconKeys = Object.keys(iconConfig);
  iconKeys.forEach((iconK) => {
    let iconTmp = iconConfig[iconK];
    let iconTmpKeys = Object.keys(iconTmp);
    let iconTT: { [key: string]: any } = {};
    iconTmpKeys.forEach((iconTmpK) => {
      let iconUrl = host + iconTmp[iconTmpK] + '';
      iconTT[iconTmpK] = iconUrl;
    });
    iconConfigTmp[iconK] = iconTT;
  });
  let shortcutConfig = tenantConfig?.shortcutConfig || {};
  shortcutConfig.showIcon = host + shortcutConfig.showIcon;
  tenantConfig.iconConfig = iconConfigTmp;
  tenantConfig.shortcutConfig = shortcutConfig;
  return tenantConfig;
};

interface Props {}

const DataInit: React.FC<Props> = (props: Props) => {
  const setGameListRecently = useSetRecoilState(gameListRecently);
  const setGameListRecommend = useSetRecoilState(gameListRecommend);
  const setBottomSupportList = useSetRecoilState(bottomSupportList);
  const setProminentSupportList = useSetRecoilState(prominentSupportList);
  const setGameCategoryList = useSetRecoilState(gameCategoryList);
  const setBannerList = useSetRecoilState(bannerList);
  const setDiaLogBannerList = useSetRecoilState(diaLogBannerList);
  const setIndexCategoryList = useSetRecoilState(indexCategoryList);
  const setAllGameMap = useSetRecoilState(allGamesMap);
  const setAllGameList = useSetRecoilState(allGamesList);
  const setPlateform = useSetRecoilState(plateformState);
  const setSupportUrl = useSetRecoilState(supportUrlState);
  const setSupportList = useSetRecoilState(supportListState);
  const isLogin = useRecoilValue(isLoginState);
  const setDataLoaded = useSetRecoilState(isDataLoadedState);
  const setMinTiXianState = useSetRecoilState(minTiXianState);
  const setPayChannelDataState = useSetRecoilState(payChannelDataState);
  const [language, setLanguage] = useRecoilState(languageState);
  const setTenantConfigState = useSetRecoilState(tenantConfigState);
  const setIncognitoModeIPState = useSetRecoilState(incognitoModeIPState);
  useScript('https://at.alicdn.com/t/c/font_4252788_3odxn3jycaw.js');

  const isIncognitoMode = () => {
    return new Promise(function (resolve, reject) {
      // 尝试在localStorage中写入一个测试项
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        // 如果写入和删除操作都成功，则可以推断不在无痕模式下      resolve(false);
      } catch (e) {
        // 如果写入和删除操作失败，则可以推断在无痕模式下
        resolve(true);
      }
    });
  };

  useEffect(() => {
    const getInitData = async () => {
      try {
        const rememberIP = localStorage.getItem('rememberIP') || '';
        const requestTime = localStorage.getItem('requestTime') || '';
        let incognito = false;
        isIncognitoMode().then(function (result) {
          if (result) {
            incognito = true;
          } else {
            incognito = false;
          }
        });

        const { data } = await services.config.getInit({
          media_id_list: '1|2|5',
          news_id_list: '1|2|5',
        });
        const game = data.data?.game || {};
        const gameList = gameMap2List(game);
        const indexCategory = data.data?.index_category;

        const tenantConfig = iconAddHost(data.data?.tenant_config);

        if (tenantConfig?.shortcutConfig) {
          if (tenantConfig?.shortcutConfig?.showName) {
            document.title = tenantConfig?.shortcutConfig?.showName;
          }

          if (tenantConfig?.shortcutConfig?.showIcon) {
            var link = document.getElementById(
              'iOSAppIcon',
            ) as HTMLAnchorElement;
            link.href = tenantConfig?.shortcutConfig?.showIcon;
          }
        }
        if (tenantConfig?.iconConfig) {
          if (tenantConfig?.iconConfig?.logoIcon?.webIcon) {
            var link = document.getElementById('webIcon') as HTMLAnchorElement;
            link.href = tenantConfig?.iconConfig?.logoIcon?.webIcon;
          }
        }
        if (tenantConfig?.colorsConfig) {
          localStorage.setItem(
            'colorsConfig',
            JSON.stringify(tenantConfig?.colorsConfig || {}),
          );
        }
        if (incognito) {
          sessionStorage.setItem(
            'setIncognitoModeIPState',
            data?.data?.requestIp || '',
          );
        } else {
          sessionStorage.setItem('setIncognitoModeIPState', '');
        }

        if (
          rememberIP === undefined ||
          rememberIP === 'undefined' ||
          rememberIP?.length < 1
        ) {
          localStorage.setItem('rememberIP', data?.data?.requestIp);
        }

        if (
          requestTime === undefined ||
          requestTime === 'undefined' ||
          requestTime?.length < 1
        ) {
          localStorage.setItem('requestTime', data?.data?.requestTime);
        }

        setTenantConfigState(tenantConfig);
        setIndexCategoryList(indexCategoryMap2List(indexCategory));
        setAllGameMap(game);
        setAllGameList(gameList);
        // 最近游戏
        const recentGameIds = indexCategory?.recent || [];
        const list = recentGameIds.map((id: number) => game[id]);
        setGameListRecently(list);
        // 推荐游戏Populares
        const recommendGameIds =
          indexCategory?.Recommend?.list ||
          indexCategory?.recommend?.list ||
          indexCategory?.Populares?.list ||
          [];
        const listRecommend = recommendGameIds.map((id: number) => game[id]);
        setGameListRecommend(listRecommend);
        // 游戏分类
        const categoryMap = data.data?.category || {};
        let categoryList = [];
        for (const key in categoryMap) {
          if (Object.prototype.hasOwnProperty.call(categoryMap, key)) {
            const element = categoryMap[key];
            const gameListInCurrentCategory = getGameListByCategoryId(
              +key,
              gameList,
            );
            categoryList.push({
              ...element,
              id: key,
              gameList: gameListInCurrentCategory,
            });
          }
        }
        setGameCategoryList(categoryList);

        // 平台列表
        const platform = data?.data?.platform;
        Object.keys(platform).forEach((key) => {
          const total = gameList.filter(
            (i) => i.platform_id === platform[key].id,
          ).length;
          platform[key].total = total;
        });
        // 根据sort对厂商排序
        let platformT = orderBy(platform, ['sort'], 'asc');
        setPlateform(platformT);

        // 首页banner列表
        // 首页banner取id=1的数据
        const bannerList = data.data?.media_list?.['1'] || [];
        const diaLogBannerList = data.data?.media_list?.['5'] || [];
        const supportList = data.data?.support_list || [];
        const bottomSupportList = data.data?.bottom_support_list || [];
        setBottomSupportList(bottomSupportList);
        const prom = data.data?.prominent_support_list || [];
        setProminentSupportList(prom);
        // 客服链接
        setSupportUrl(data.data?.support_url ?? '');
        setSupportList(supportList);
        setBannerList(bannerList);
        setDiaLogBannerList(diaLogBannerList);
        // 最低提现额
        const minTixian = data.data?.min_tixian_money || 0;
        const payChannelData = data.data?.payChannelData || {};

        setPayChannelDataState(payChannelData);
        setMinTiXianState(minTixian);
        setDataLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };

    getInitData();
  }, []);
  return <Box></Box>;
};

export default DataInit;
