import pgImg from '@/assets/images/PG@2x.png';
import gameDefault from '@/assets/images/pic_loading.png';
import ppImg from '@/assets/images/PP@2x.png';
import tadaImg from '@/assets/images/TADA@2x.png';
import { Box } from '@mui/material';
import { CSSProperties } from 'react';
import useBoolean from '../hooks/useBoolean';

interface Props {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
  aspectRatio: string;
  defaultSrc?: string;
  style?: CSSProperties | undefined;
}

const ImageWithLoading: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useBoolean(true);
  const [error, setError] = useBoolean(false);
  // 根据传入的 imageType 参数返回对应的图片 URL
  const getImageUrl = (picStr: any) => {
    let imageUrl = '';
    if (picStr == null) {
      imageUrl = pgImg;
    } else {
      let parts = picStr.split('_');
      let result = '';

      if (parts.length >= 2) {
        result = parts[parts.length - 2];
      }
      switch (result) {
        case '1':
          imageUrl = pgImg;
          break;
        case '2':
          imageUrl = tadaImg;
          break;
        case '3':
          imageUrl = ppImg;
          break;
        default:
          // 默认情况下使用 pgImg
          imageUrl = pgImg;
          break;
      }
    }
    return imageUrl;
  };
  return (
    <Box
      sx={{
        width: props.width,
        height: props.height,
        style: props.style,
        aspectRatio: props.aspectRatio,
        backgroundImage: `url(${gameDefault})`,
        overflow: 'hidden',
        backgroundSize: '100%',
        animation: 'img-loading 1.2s linear infinite',
        position: 'relative',
        borderRadius: '8px',
      }}
    >
      <img
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          opacity: loading ? 0 : 1,
        }}
        onLoad={setLoading.off}
        onError={setError.on}
        src={error ? props.defaultSrc : props.src}
        alt={props.alt}
      />
    </Box>
  );
};

export default ImageWithLoading;
