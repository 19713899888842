import HOT_ACTIVE from '@/assets/images/hot_ative.png';
import LOBBY_I from '@/assets/images/lobby_i.png';
import PG_ACTIVE from '@/assets/images/pg_active.png';
import { default as PG_I } from '@/assets/images/pg_i.png';
import IMAGE_PP from '@/assets/images/pp_active.png';
import PP_I from '@/assets/images/pp_i.png';
import SLOTS_ACTIVE from '@/assets/images/slots_active.png';
import IMAGE_TADA from '@/assets/images/tada_active.png';
import TADA_I from '@/assets/images/tada_i.png';
import IMAGE_BONUS from '@/assets/images/Type_Bonus.png';
import IMAGE_HOT from '@/assets/images/Type_Game.png';
import IMAGE_HOME from '@/assets/images/Type_Home.png';
import IMAGE_GAME from '@/assets/images/Type_Hot.png';
import IMAGE_LIVE from '@/assets/images/Type_Live.png';
import IMAGE_NEW from '@/assets/images/Type_New.png';
import IMAGE_SLOTS from '@/assets/images/Type_Slots.png';
import useLang from '@/hooks/useLang';
import { indexCategoryList } from '@/stores/config';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

const ImageMap = {
  slots: SLOTS_ACTIVE,
  live: IMAGE_LIVE,
  bonus: IMAGE_BONUS,
  new: IMAGE_NEW,
  popular: HOT_ACTIVE,
  populares: HOT_ACTIVE,
  recent: IMAGE_NEW,
  recommend: IMAGE_BONUS,
  hot: HOT_ACTIVE,
  tada:IMAGE_TADA,
  pg:PG_ACTIVE,
  pp:IMAGE_PP
};

const ImageMap_i = {
  slots: IMAGE_SLOTS,
  live: IMAGE_LIVE,
  bonus: IMAGE_BONUS,
  new: IMAGE_NEW,
  popular: IMAGE_HOT,
  populares: HOT_ACTIVE,
  recent: IMAGE_NEW,
  recommend: IMAGE_BONUS,
  hot: IMAGE_GAME,
  tada:TADA_I,
  pg:PG_I,
  pp:PP_I
};

export const useHomeTabs = () => {
  const indexCategoryListValue = useRecoilValue(indexCategoryList);
  
  const { __ } = useLang();
  const tabList = useMemo(() => {
    return [
      {
        title: __('Lobby'),
        image: IMAGE_HOME,
        image_i:LOBBY_I
      },
      ...indexCategoryListValue
        .map((item, index) => {
          const key = item.title.toLowerCase();
          return {
            title: item.title,
            image: ImageMap[key as keyof typeof ImageMap] ?? IMAGE_GAME,
            image_i: ImageMap_i[key as keyof typeof ImageMap_i] ?? IMAGE_GAME,
          };
        })
        // 做个简单的排序，slots 放第一位 live 放第二位，其他的按照 indexCategoryListValue 顺序
        .sort((a, b) => {
          if (a.title.toLowerCase() === 'slots') {
            return -1;
          }
          if (b.title.toLowerCase() === 'slots') {
            return 1;
          }
          if (a.title.toLowerCase() === 'live') {
            return -1;
          }
          if (b.title.toLowerCase() === 'live') {
            return 1;
          }
          return 0;
        }),
    ];
  }, [indexCategoryListValue]);

  return {
    tabList,
  };
};
