import ICON_COMPLETE from '@/assets/icons/icon_complete.png';
import IMAGE_NO_SIGN from '@/assets/sign/noSignBox.png';
import IMAGE_SIGNBOX7 from '@/assets/sign/signBox7.png';
import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import { alpha, Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
interface SignCardProps {
  dayData: any;
  row: number;
  // 最大行
  maxRow: number;
  // 所在周最大天数
  maxDayNum: number;
  col: number;
  dayNum?: number;
  todaySign?: number;
  lastSign?: number;
  active: boolean;
  onClick?: () => void;
}

export const SignCardView28 = ({
  dayData,
  row,
  maxRow,
  maxDayNum,
  col,
  lastSign,
  todaySign,
  active,
  onClick,
}: SignCardProps) => {
  const { __ } = useLang();
  const theme = useTheme();
  let dayNum = dayData.day;
  let activeTmp = dayData.is_sign == 1;
  let show = dayNum == parseInt(`${lastSign}`, 10) + 1 || activeTmp;
  let today = todaySign === 1 ? lastSign : Number(lastSign) + 1;
  let isToday = today == dayNum;
  let dayTmp = col + 1;
  let rowMod = row % 2;
  let showVerRB = dayTmp == 7 && rowMod == 0 && row != maxRow - 1; //右下连接，（最后一个，双数排）
  let showVerRT = dayTmp == maxDayNum && rowMod == 1; //右上连接（最后一个，单数排）
  let showVerLB = dayTmp == 1 && rowMod == 1 && row != maxRow - 1; //左下连接，（第一个，单数排）
  let showVerLT = dayTmp == 1 && rowMod == 0 && row != 0;
  let showLeft = col > 0 || row > 0; //左中线
  let showRight = true; //右中线

  if (rowMod > 0) {
    // 单数排
    if (row + 1 === maxRow && col === 0) {
      // 最后一排且第一个，隐藏左中线
      showLeft = false;
    }
  } else if (row + 1 === maxRow && col + 1 === maxDayNum) {
    // 双数排，最后一天隐藏右中线
    showRight = false;
  }

  // if (row + 1 === maxRow && col + 1 === maxDayNum) {
  //   console.log('maxDayNum:', maxDayNum);
  //   console.log('maxRow:', maxRow);
  //   console.log('rowMod:', rowMod);
  //   if (rowMod > 0) {
  //     // 单数排
  //     showLeft = false;
  //   } else {
  //     showRight = false;
  //   }
  // }

  return (
    <Center
      onClick={onClick}
      textAlign="center"
      position="relative"
      pb={row != maxRow - 1 ? '16px' : '0px'}
    >
      <Grid
        container
        spacing={2}
        sx={{
          position: 'absolute',
          top: '14px',
        }}
      >
        <Grid item xs={6}>
          <Stack
            sx={{
              bgcolor: show ? 'primary.main' : 'background.neutral',
              height: showLeft ? (show ? '4px' : '1px') : 0,
            }}
          ></Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack
            sx={{
              bgcolor: activeTmp ? 'primary.main' : 'background.neutral',
              height: showRight ? (activeTmp ? '4px' : '1px') : 0,
            }}
          ></Stack>
        </Grid>
      </Grid>

      <Stack alignItems="center" zIndex={10}>
        <Box
          sx={{
            bgcolor: activeTmp ? 'primary.main' : 'background.neutral',
            borderColor: activeTmp ? 'primary.main' : 'background.neutral',
            borderRadius: 999,
            p: '6px',
          }}
        >
          <img
            src={
              activeTmp
                ? ICON_COMPLETE
                : dayData.weight == 2
                ? IMAGE_SIGNBOX7
                : IMAGE_NO_SIGN
            }
            alt=""
            style={{ width: 16, height: 16 }}
          />
        </Box>

        <Typography
          variant="body2"
          color={alpha(theme.palette.info.main, isToday ? 1 : 0.3)}
          mt={'5px'}
        >
          {isToday
            ? __('Today')
            : __('signDay').replace('{n}', `${dayData.day}`)}
        </Typography>
      </Stack>
      {/* 右边连接线 */}
      <Stack
        sx={{
          bgcolor: activeTmp ? '#A74EF5' : '#3C3952',
          width: showVerRB ? (activeTmp ? '4px' : '1px') : 0,
          height: '56px',
          position: 'absolute',
          right: 0,
          top: '14px',
        }}
      />
      <Stack
        sx={{
          bgcolor: activeTmp ? '#A74EF5' : '#3C3952',
          width: showVerRT ? (activeTmp ? '4px' : '1px') : 0,
          position: 'absolute',
          right: 0,
          top: 0,
          height: '14px',
        }}
      />
      {/* 左边连接线 */}
      <Stack
        sx={{
          bgcolor: activeTmp ? '#A74EF5' : '#3C3952',
          width: showVerLB ? (activeTmp ? '4px' : '1px') : 0,
          height: '56px',
          position: 'absolute',
          left: 0,
          top: '14px',
        }}
      />
      <Stack
        sx={{
          bgcolor: activeTmp ? '#A74EF5' : '#3C3952',
          width: showVerLT ? (activeTmp ? '4px' : '1px') : 0,
          position: 'absolute',
          left: 0,
          top: 0,
          height: '14px',
        }}
      />
    </Center>
  );
};
