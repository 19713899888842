import { Box, Skeleton } from '@mui/material';

interface Props {}

const CommonSkeleton: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={30}
        sx={{ mt: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={30}
        sx={{ mt: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'70%'}
        height={30}
        sx={{ mt: '20px' }}
      />
    </Box>
  );
};

export default CommonSkeleton;
