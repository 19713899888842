import services from '@/services';
import {
  boxesState,
  inviteActivityState,
  inviteEventsInfoState,
} from '@/stores/invite';
import { ActivityType, ScrollMessageDto } from '@/types';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useAuth from './useAuth';

export interface ActivityDto {
  id: string;
  type: number;
  blurb: { zhCN: string; enUS: string; ptBR: string };
  rules: Array<{
    activityId: string;
    ruleKey: number;
    id: string;
    ruleState: number;
    rules: Array<{
      ruleName: string;
      value: string;
      ruleType: number;
    }>;
    activityPrizeList: Array<{
      activityId: string;
      id: string;
      prizeValue: {
        value: number;
      };
    }>;
  }>;
  userActivity?: {
    id: string;
    activityId: string;
    tripTimes: number;
    acceptTime?: number;
    unReceiveTriggers?: Array<{
      ruleKey: number;
      triggerTime: number;
    }>;
    completeRules?: Array<{
      value: string;
      currentValue: string;
    }>;
    receivedAmt?: number;
  };
}

export enum BoxStatus {
  未开始 = 0,
  进行中 = 1,
  待开启 = 2,
  已开启 = 3,
}

export interface BoxDto {
  money: number;
  targetInvite: number;
  status: BoxStatus;
  ruleKey: number;
}

// 邀新宝箱活动
export const useActivity = (type: ActivityType) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [detail, setDetail] = useRecoilState(inviteActivityState);
  const [activityId, setActivityId] = useState<string>('');
  const [eventInfo, setEventInfo] = useRecoilState(inviteEventsInfoState);
  const [boxes, setBoxes] = useRecoilState(boxesState);
  const { isLogin } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      // services.config.getAgentSummary();
      const res = await services.config.getActivityList(type);
      const activityId = res?.list?.[0]?.id;
      const activityType = res?.list?.[0]?.type;

      if (!activityId) {
        setLoading(false);
        return;
      }

      setActivityId(activityId);

      const { data } = await services.config.getActivityDetail({
        activityId,
      });

      const tripTimes = (data as ActivityDto).userActivity?.tripTimes || 0;
      const boxList = generateBoxes(data, tripTimes);

      const acceptTime = data.userActivity?.acceptTime;
      const info = await services.config.getInviteBoxEventsInfo(acceptTime);
      setEventInfo(info);

      setDetail({
        ...data,
        type: activityType,
      });
      setBoxes(boxList);
      setLoading(false);
    };

    fetchData();
  }, [type, isLogin]);

  useEffect(() => {
    if (!detail) {
      return;
    }
    const tripTimes = detail.userActivity?.tripTimes || 0;
    const boxList = generateBoxes(detail, tripTimes);
    setBoxes(boxList);
  }, []);

  const refetchDetail = async () => {
    if (!activityId) {
      return;
    }

    const { data } = await services.config.getActivityDetail({
      activityId,
    });

    const tripTimes = (data as ActivityDto).userActivity?.tripTimes || 0;
    const acceptTime = data.userActivity?.acceptTime;
    const info = await services.config.getInviteBoxEventsInfo(acceptTime);
    const boxList = generateBoxes(data, tripTimes);
    setEventInfo(info);
    setDetail((prev) => {
      return {
        ...data,
        type: prev?.type,
      };
    });
    setBoxes(boxList);
  };

  return {
    loading,
    detail,
    boxes,
    refetchDetail,
    // activityList,
    eventInfo,
  };
};

export const useRefreshActivity = (type: ActivityType) => {
  const setDetail = useSetRecoilState(inviteActivityState);
  const setEventInfo = useSetRecoilState(inviteEventsInfoState);
  const setBoxes = useSetRecoilState(boxesState);
  const { isLogin, getUserInfo } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);

  const refresh = async () => {
    if (!isLogin) {
      return;
    }

    setLoading(true);
    // 刷新代理钱包
    getUserInfo();

    const res = await services.config.getActivityList(type);
    const activityId = res?.list?.[0]?.id;

    if (!activityId) {
      setLoading(false);
      return;
    }

    const { data } = await services.config.getActivityDetail({
      activityId,
    });

    const tripTimes = (data as ActivityDto).userActivity?.tripTimes || 0;
    const boxList = generateBoxes(data, tripTimes);

    const acceptTime = data.userActivity?.acceptTime;
    const info = await services.config.getInviteBoxEventsInfo(acceptTime);
    setEventInfo(info);
    setDetail((prev) => {
      return {
        ...data,
        type: prev?.type,
      };
    });
    setBoxes(boxList);
    setLoading(false);
  };
  return {
    refresh,
    loading,
  };
};

const generateBoxes = (data: ActivityDto, tripTimes: number) => {
  return data.rules.map((rule, index) => {
    let status = BoxStatus.未开始;
    if (tripTimes === index) {
      status = BoxStatus.进行中;
    } else if (tripTimes < index) {
      status = BoxStatus.未开始;
    } else {
      if (
        (data as ActivityDto).userActivity?.unReceiveTriggers?.find(
          (trigger) => +trigger.ruleKey === +rule.ruleKey,
        )
      ) {
        status = BoxStatus.待开启;
      } else {
        status = BoxStatus.已开启;
      }
    }

    return {
      money: rule.activityPrizeList[0].prizeValue.value,
      targetInvite: Number(rule.rules[0].value),
      status,
      ruleKey: rule.ruleKey,
    };
  });
};

export const useActivityScrollrMessages = (rewardType?: number) => {
  const [messages, setMessages] = useState<Array<ScrollMessageDto>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { isLogin } = useAuth();

  useEffect(() => {
    const fetchData = async (type: number) => {
      setLoading(true);
      try {
        const res = await services.config.getScrollMessage(type);

        if (!res) {
          return;
        }

        if (Array.isArray(res)) {
          setMessages(res);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (rewardType !== undefined && isLogin) {
      fetchData(rewardType);
    }
  }, [rewardType, isLogin]);

  const refetch = async () => {
    if (!rewardType) {
      return;
    }

    setLoading(true);
    try {
      const res = await services.config.getScrollMessage(rewardType);

      if (!res) {
        return;
      }

      if (Array.isArray(res)) {
        setMessages(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    messages,
    loading,
    refetch,
  };
};

export const useDepositScrollrMessages = (rewardType: number) => {
  const [messages, setMessages] = useState<Array<ScrollMessageDto>>([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await services.config.getScrollMessage(rewardType);
      if (!res) {
        return;
      }

      if (Array.isArray(res)) {
        setMessages(res);
      }
    };
    fetchData();
  }, [rewardType]);

  return {
    messages,
  };
};
