import CommonSkeleton from '@/components/CommonSkeleton';
import DatePicker from '@/components/DatePicker/DatePicker';
import EmptyData from '@/components/EmptyData';
import Center from '@/components/center';
import { AgentListItemDto, useAgentList } from '@/hooks/useAgentList';
import useLang from '@/hooks/useLang';
import { ColorfulBg } from '@/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { NewPlayersTotalInfoCard } from './NewPlayersTotalInfoCard';

export const NewPlayers = () => {
  const [start, setStart] = useState<string>(
    dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
  );
  const [end, setEnd] = useState<string>(
    dayjs().add(1, 'day').format('YYYY-MM-DD'),
  );
  const { list, isLoading, total } = useAgentList(start, end);

  return (
    <Box px={1.5} pt={1} pb={2}>
      <Box
        sx={{
          border: `1px solid ${ColorfulBg()}`,
          borderRadius: '5px',
          py: 0,
          px: 2.5,
          mb: 2,
        }}
      >
        <Stack
          sx={{ height: '40px' }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Stack
            textAlign="center"
            flex="auto"
            direction="row"
            alignItems="center"
          >
            <DatePicker
              date={start}
              onChange={(v) => setStart(v)}
              maxDate={dayjs(end)}
              anchorOriginHorizontal="left"
              transformOriginHorizontal="left"
            />
            <Typography variant="body1">-</Typography>
            <DatePicker
              date={end}
              onChange={(v) => setEnd(v)}
              minDate={dayjs(start)}
              anchorOriginHorizontal="right"
              transformOriginHorizontal="right"
            />
          </Stack>
          <CalendarMonthIcon sx={{ fontSize: 16 }} />
        </Stack>
      </Box>
      <NewPlayersTotalInfoCard data={total} />
      <NewPlayerList loading={isLoading} members={list} />
    </Box>
  );
};

const NewPlayerList = (props: {
  loading: boolean;
  members: AgentListItemDto[];
}) => {
  const { loading, members } = props;
  const { __ } = useLang();

  if (loading) {
    return <CommonSkeleton />;
  }

  if (members.length === 0) {
    return (
      <Center py={4}>
        <EmptyData></EmptyData>
      </Center>
    );
  }
  return (
    <Grid container spacing={1} columns={24}>
      {members.map((item, idx) => {
        return (
          <Grid key={idx} xs={24} item>
            <Box
              sx={{
                borderRadius: 1,
                p: 2,
                bgcolor: '#313D4D',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body1"
                  color="text.secondary"
                  fontWeight={500}
                >
                  ID
                </Typography>
                <Typography variant="subtitle2" fontWeight={700}>
                  {item.id}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={0.5}
              >
                <Typography
                  variant="body1"
                  color="text.secondary"
                  fontWeight={500}
                >
                  {__('Invited Date')}
                </Typography>
                <Typography variant="subtitle2" fontWeight={700}>
                  {dayjs(+(item?.reg_time || 0) * 1000).format(
                    'YYYY-MM-DD HH:mm',
                  )}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
