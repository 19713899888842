import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonTitleHead from '../../components/CommonTitleHead';
import TransationCard from '../../components/transations/TransationCard';
import useScrollToTop from '../../hooks/useScrollToTop';
import { TransationsTabType } from '../../types';

interface Props {}

const AccountTabItem = (props: {
  tab: TransationsTabType;
  activeTab: TransationsTabType;
  onClick: () => void;
}) => {
  const isActive = props.tab === props.activeTab;
  return (
    <ListItemButton sx={{ my: 2 }} onClick={props.onClick}>
      <Stack
        sx={{ width: '100%' }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2" fontWeight={isActive ? 600 : 400}>
          {props.tab}
        </Typography>
        {/* {isActive ? } */}
        <ChevronRightIcon sx={{ opacity: isActive ? 1 : 0 }} />
      </Stack>
    </ListItemButton>
  );
};

const TransationPage: React.FC<Props> = (props: Props) => {
  const [activeTab, setActiveTab] = useState<TransationsTabType>(
    TransationsTabType.Transactions,
  );
  const navigate = useNavigate();
  useScrollToTop();

  return (
    <Box>
      <CommonTitleHead title="Transations" onBack={() => navigate(-1)} />
      <Stack direction={{ xs: 'column' }} spacing="20px">
        <Box flex={1}>
          <Box
            sx={{ bgcolor: '#1D1839', borderRadius: '10px', px: 2, py: '1px' }}
          >
            <List sx={{ py: 0 }}>
              <AccountTabItem
                tab={TransationsTabType.Transactions}
                activeTab={activeTab}
                onClick={() => setActiveTab(TransationsTabType.Transactions)}
              />
              <Divider sx={{ borderColor: '#272142' }} />
              <AccountTabItem
                tab={TransationsTabType.Deposists}
                activeTab={activeTab}
                onClick={() => setActiveTab(TransationsTabType.Deposists)}
              />
              <Divider sx={{ borderColor: '#272142' }} />
              <AccountTabItem
                tab={TransationsTabType.Withdraws}
                activeTab={activeTab}
                onClick={() => setActiveTab(TransationsTabType.Withdraws)}
              />
            </List>
          </Box>
        </Box>
        <Box
          flex={3}
          // sx={{ bgcolor: "#1D1839", borderRadius: "10px", p: "20px 20px 30px" }}
        >
          <Grid container spacing="20px" columns={24}>
            <Grid item xs={24} md={12}>
              <TransationCard />
            </Grid>
            <Grid item xs={24} md={12}>
              <TransationCard />
            </Grid>
            <Grid item xs={24} md={12}>
              <TransationCard />
            </Grid>
            <Grid item xs={24} md={12}>
              <TransationCard />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default TransationPage;
