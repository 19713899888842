import gameBg from '@/assets/images/gameBg.png';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import Center from '../center';
import { GameCard } from './GameList';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}

export const FlishGameList = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return <FlishGameListMobile {...props} />;
  }
  return <FlishGameListWeb {...props} />;
};

export const FlishGameListWeb = (props: Props) => {
  const { __ } = useLang();
  const showList = props.list.slice(0, 8);

  return (
    <Box sx={{ position: 'relative', mt: '16px', pt: '24px' }}>
      {props?.icon?.square ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'block',
          }}
        >
          <img
            src={props?.icon?.square}
            alt=""
            style={{ width: '48px', height: '48px' }}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pb: '12px',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${props?.bgImg?.vertocal})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '98px',
            mb: '12px',
          }}
        >
          <Center sx={{ mt: '32px' }} flexDirection="column">
            <Typography
              variant="subtitle1"
              // className="br-game-font"
              className="home-game-list-font"
              sx={{
                fontWeight: '700',
                px: 1,
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
              }}
            >
              {props?.title}
            </Typography>
            <Button
              sx={{
                height: '24px',
                color: 'text.secondary',
                bgcolor: 'background.neutral',
                borderRadius: '12px',
                fontSize: 12,
                fontWeight: 400,
                minWidth: 0,
                px: 1,
                mt: '8px',
              }}
              variant="text"
              size="small"
              onClick={props.onClickMore}
            >
              {__('View All')}
            </Button>
          </Center>
        </Box>
        <Grid
          container
          gridRow={1}
          columns={24}
          spacing={{ xs: 1.5, sm: 2 }}
          sx={{ px: '12px' }}
        >
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} md={3}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export const FlishGameListMobile = (props: Props) => {
  const { __ } = useLang();
  const navigate = useNavigate();

  const showList = props.list.slice(0, 6);

  return (
    <Box
      sx={{
        position: 'relative',
        mt: '16px',
        pt: '24px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'block',
        }}
      >
        <img
          src={props?.icon?.square}
          alt=""
          style={{ width: '48px', height: '48px' }}
        />
      </Box>

      <Box
        sx={{
          bgcolor: 'background.paper',
          pb: '8px',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${props?.bgImg?.transverse || gameBg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '98px',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            mb: '8px',
          }}
        >
          <Center sx={{ mt: '32px' }} flexDirection="column">
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '700',
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
              }}
              className="home-game-list-font"
            >
              {props?.title}
            </Typography>
            <Button
              sx={{
                height: '24px',
                color: 'text.secondary',
                bgcolor: 'background.neutral',
                borderRadius: '4px',
                fontSize: 12,
                fontWeight: 400,
                minWidth: 0,
                px: 1,
                mt: '8px',
              }}
              variant="text"
              size="small"
              onClick={props.onClickMore}
            >
              {__('View All')}
            </Button>
          </Center>
        </Box>
        <Grid
          container
          columns={24}
          spacing={{ xs: '4.5px', sm: 2 }}
          sx={{ px: '8px' }}
        >
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} xs={8}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
