import LEVEL0 from './images/level0.png';
import LEVEL1 from './images/level1.png';
import LEVEL10 from './images/level10.png';
import LEVEL2 from './images/level2.png';
import LEVEL3 from './images/level3.png';
import LEVEL4 from './images/level4.png';
import LEVEL5 from './images/level5.png';
import LEVEL6 from './images/level6.png';
import LEVEL7 from './images/level7.png';
import LEVEL8 from './images/level8.png';
import LEVEL9 from './images/level9.png';

import ICON0 from './images/vip_icon_vip0.png';
import ICON1 from './images/vip_icon_vip1.png';
import ICON10 from './images/vip_icon_vip10.png';
import ICON2 from './images/vip_icon_vip2.png';
import ICON3 from './images/vip_icon_vip3.png';
import ICON4 from './images/vip_icon_vip4.png';
import ICON5 from './images/vip_icon_vip5.png';
import ICON6 from './images/vip_icon_vip6.png';
import ICON7 from './images/vip_icon_vip7.png';
import ICON8 from './images/vip_icon_vip8.png';
import ICON9 from './images/vip_icon_vip9.png';

import VIP_ICON0 from './images/level-VIP.0.png';
import VIP_ICON1 from './images/level-VIP.1.png';
import VIP_ICON10 from './images/level-VIP.10.png';
import VIP_ICON2 from './images/level-VIP.2.png';
import VIP_ICON3 from './images/level-VIP.3.png';
import VIP_ICON4 from './images/level-VIP.4.png';
import VIP_ICON5 from './images/level-VIP.5.png';
import VIP_ICON6 from './images/level-VIP.6.png';
import VIP_ICON7 from './images/level-VIP.7.png';
import VIP_ICON8 from './images/level-VIP.8.png';
import VIP_ICON9 from './images/level-VIP.9.png';

export const BackgroundColors = [
  '#724A3D',
  '#AB8D5E',
  '#929AB6',
  '#CB9D6B',
  '#6D8FD2',
  '#9A8BE5',
  '#569FF4',
  '#DDB756',
  '#E97D74',
  '#C067FF',
  '#444347',
];

export const VipProcessBarColors = [
  '#522313',
  '#6F4C1E',
  '#535C79',
  '#93561D',
  '#386087',
  '#5057A0',
  '#315BA5',
  '#8A6721',
  '#AC4646',
  '#793EA4',
  '#111',
];

export const VipLevelSrcs = [
  LEVEL0,
  LEVEL1,
  LEVEL2,
  LEVEL3,
  LEVEL4,
  LEVEL5,
  LEVEL6,
  LEVEL7,
  LEVEL8,
  LEVEL9,
  LEVEL10,
];

export const VipIcons = [
  ICON0,
  ICON1,
  ICON2,
  ICON3,
  ICON4,
  ICON5,
  ICON6,
  ICON7,
  ICON8,
  ICON9,
  ICON10,
];

export const VipIcon2 = [
  VIP_ICON0,
  VIP_ICON1,
  VIP_ICON2,
  VIP_ICON3,
  VIP_ICON4,
  VIP_ICON5,
  VIP_ICON6,
  VIP_ICON7,
  VIP_ICON8,
  VIP_ICON9,
  VIP_ICON10,
];
