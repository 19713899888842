import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenWithdrawerState } from '@/stores/auth';
import { globalMaxWidth } from '@/theme';
import { Box, Dialog, Drawer } from '@mui/material';
import { useRecoilState } from 'recoil';
import DrawerHeder from '../../pages/Me/components/DrawerHeder';
import WithdrawDrawerConten from './WithdrawDrawerConten';

interface Props {}

const WithdrawDrawer = (props: Props) => {
  const { __ } = useLang();
  const [openWithdraw, setOpenWithdraw] = useRecoilState(isOpenWithdrawerState);
  const isMobile = useResponsive('down', 'md');

  const _onClose = () => {
    setOpenWithdraw(false);
  };

  if (isMobile) {
    return (
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={openWithdraw}
        // onOpen={() => setOpenWithdraw(true)}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        onClose={_onClose}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
            maxWidth: globalMaxWidth,
            left: {
              xs: 0,
              sm: `calc(50% - ${globalMaxWidth / 2}px)`,
            },
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: Math.min(window.innerWidth, globalMaxWidth),
            // height: isMobile ? '75vh' : '100vh',
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder title={__('提取')} onClose={_onClose} />

          <WithdrawDrawerConten />
        </Box>
      </Drawer>
    );
  }
  return (
    <Dialog
      open={openWithdraw}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={_onClose}
      aria-labelledby="sign-in"
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '400px',
          height: '80vh',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder title={__('提取')} onClose={_onClose} />
        <WithdrawDrawerConten />
      </Box>
    </Dialog>
  );
};

export default WithdrawDrawer;
