import useLang from '@/hooks/useLang';
// import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Box, Grid, Stack, Typography } from '@mui/material';
// import IMAGE_PARTNER_H5 from '../assets/images/footer-partner-h5.png';
// import IMAGE_PARTNER from '../assets/images/footer-partner.png';
// import { footerHeight, h5FooterHeight } from '../theme';

import FOOTER_CQ9 from '@/assets/footer/CQ9.png';
import FOOTER_ESPORTS from '@/assets/footer/ESPORTS.png';
import FOOTER_EVOLUTION from '@/assets/footer/Evolution.png';
import FOOTER_EVOPLAY from '@/assets/footer/Evoplay.png';
import FOOTER_PG from '@/assets/footer/PG.png';
import FOOTER_GOOGLE from '@/assets/footer/google.png';
import Center from '@/components/center';
import 'swiper/css';
import FooterLinks from './FooterLinks';
import { LocaleButtonMobile } from './Siderbar/LocaleButton';

const PartnerBox = (props: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 0.5,
      }}
    >
      {props.children}
    </Box>
  );
};

const H5Footer = () => {
  const { __, isEn } = useLang();
  return (
    <Box maxWidth={800} mx="auto">
      <Typography variant="body1" fontStyle="italic" textAlign="left" mb={1}>
        {__('Cooperating Partners')}
      </Typography>
      <Grid container columns={24} spacing={1}>
        <Grid item xs={8} md={6}>
          <PartnerBox>
            <img
              src={FOOTER_GOOGLE}
              alt=""
              style={{ width: 'auto', height: '32px' }}
            />
          </PartnerBox>
        </Grid>
        <Grid item xs={8} md={6}>
          <PartnerBox>
            <img
              src={FOOTER_EVOPLAY}
              alt=""
              style={{ width: 'auto', height: '32px' }}
            />
          </PartnerBox>
        </Grid>
        <Grid item xs={8} md={6}>
          <PartnerBox>
            <img
              src={FOOTER_ESPORTS}
              alt=""
              style={{ width: 'auto', height: '32px' }}
            />
          </PartnerBox>
        </Grid>
        <Grid item xs={8} md={6}>
          <PartnerBox>
            <img
              src={FOOTER_PG}
              alt=""
              style={{ width: 'auto', height: '32px' }}
            />
          </PartnerBox>
        </Grid>
        <Grid item xs={8} md={6}>
          <PartnerBox>
            <img
              src={FOOTER_EVOLUTION}
              alt=""
              style={{ width: 'auto', height: '32px' }}
            />
          </PartnerBox>
        </Grid>
        <Grid item xs={8} md={6}>
          <PartnerBox>
            <img
              src={FOOTER_CQ9}
              alt=""
              style={{ width: 'auto', height: '32px' }}
            />
          </PartnerBox>
        </Grid>
      </Grid>

      <Stack mt={1.5} spacing={1} color="text.secondary" textAlign="left">
        {isEn ? (
          <Typography variant="body2" fontWeight={500} lineHeight={1}>
            CC group is one of the most renowned international online casino
            operating companies, offering a wide variety of exciting games such
            as live dealer games, slots, fishing, lotteries, sports and much
            more. We are authorized and regulated by the government of Curação,
            operating with license number CC GRUPO issued to 8048/JAZ. We go
            through all the checks.
          </Typography>
        ) : (
          <Typography variant="body2" fontWeight={500} lineHeight={1}>
            O CC grupo é uma das mais renomadas empresas internacionais de
            operação de cassino online, oferecendo uma ampla variedade de jogos
            empolgantes, como jogos ao vivo com crupie real, slots, pesca,
            loterias, esportes e muito mais. Estamos autorizados e
            regulamentados pelo governo de Curação, operando com a licença
            número CC GRUPO emitida para a 8048/JAZ. Passamos por todas as
            verificações.
          </Typography>
        )}

        <Typography variant="body2" fontWeight={500} lineHeight={1} mt={1}>
          {__('copyright').replace('{host}', `${window.location.host}`)}
        </Typography>
      </Stack>

      <Center display={{ xs: 'flex', md: 'none' }} pt={3} pb={8}>
        <LocaleButtonMobile />
      </Center>
    </Box>
  );
};

const Footer = () => {
  return (
    <Box
      sx={[
        {
          bgcolor: { xs: 'transparent', md: 'background.default' },
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          pt: 2,
          flexGrow: 1,
        },
      ]}
    >
      <Stack>
        <FooterLinks />
        <H5Footer />
      </Stack>
    </Box>
  );
};

export default Footer;
