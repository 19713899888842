import { Request } from '@/utils';
import { testHost } from './host';

export async function getGameUrl(params: { game_id: number }) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_goto_game.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

export const getRankList = async (params: {
  type_id: number;
  day_nun: string;
  data_num: number;
}) => {
  try {
    const { data } = await Request.post(
      `${testHost}/api_ranking_list.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getRewardList = async (params: {
  card_type: number;
  page: number;
  page_size: number;
}) => {
  try {
    const { data } = await Request.post(
      `${testHost}/api_card_list.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const useReward = async (params: { card_id: number }) => {
  try {
    const { data } = await Request.post(
      `${testHost}/api_usa_card.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
};
