import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { getUserInfo } from '@/services/auth';
import { isOpenWithdrawerState, withdrawerTypeState } from '@/stores/auth';
import { LoadingButton } from '@mui/lab';
import { useSetRecoilState } from 'recoil';

interface Props {
  bgColorT?: string;
  type: number; //1:用户钱包，2：代理钱包
}

const WithdrawButton: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');

  const setOpenWithdraw = useSetRecoilState(isOpenWithdrawerState);
  const setWithdrawType = useSetRecoilState(withdrawerTypeState);
  const [loading, setLoading] = useBoolean();
  const { user } = useAuth();
  const { __ } = useLang();
  const { bgColorT } = props;

  const handleClickWithdraw = async () => {
    if (loading) {
      return;
    }
    if (user?.cpfState == 1) {
      await getUserInfo();
    }
    setWithdrawType(props.type);
    setOpenWithdraw(true);
    // setLoading.on();

    // const cpfList = await services.wallet.getCPFList({ page: 1, pagesize: 20 });
    // // 绑定电话且有cpf直接提现
    // let cpfInfo = cpfList?.length > 0 ? cpfList[0] : {};
    // setOpenWithdraw(true);
    // setWithdrawInfo({
    //   cpfId: cpfInfo?.id || '',
    //   outType: 1,
    //   cpfInfo,
    // });
    // if (cpfList?.length > 0) {
    //   setOpenWithdraw(true);
    //   setWithdrawInfo({
    //     cpfId: cpfList[0].id,
    //     outType: 1,
    //     cpfInfo: cpfList[0],
    //   });
    // } else {
    //   // 绑定CPF
    //   setOpenWithdrawValid(true);
    //   // setOpenBindCPF(true);
    // }
    // setLoading.off();
  };
  return (
    <>
      <LoadingButton
        size="small"
        variant="contained"
        sx={[
          {
            bgcolor: bgColorT || 'background.neutral',
            height: '40px',
            borderRadius: '8px',
          },
          !isMobile ? { width: '147px' } : { width: 'auto', flex: 1 },
        ]}
        onClick={handleClickWithdraw}
      >
        {__('提取')}
      </LoadingButton>
      {/* <BindCPFDrawer />
      <BindPhoneDrawer />
      <WithdrawDrawer />
      <UnSetPayPwdDrawer />
      <SetPaymentDrawer /> */}
    </>
  );
};

export default WithdrawButton;
