import { ColorfulBg } from "@/styles";
import { alpha, PaletteColor } from '@mui/material/styles';

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

const primary = ColorfulBg();
const textPrimary = '#FFFFFF';
// const primary15Opacity = alpha(primary, 0.15);
// const primary65Opacity = alpha(primary, 0.65);

// SETUP COLORS
 
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  // lighter: 'rgba(0, 145, 255, .35)',
  // light: primary15Opacity,
  main: ColorfulBg(),
  // dark: primary,
  // darker: 'rgba(0, 145, 255, 1.65)',
  contrastText: '#fff',
} as PaletteColor;

const SECONDARY = {
  // lighter: '#D6E4FF',
  // light: '#84A9FF',
  main: '#A74EF5',
  // dark: '#1939B7',
  // darker: '#091A7A',
  contrastText: '#fff',
} as PaletteColor;

const INFO = {
  // lighter: '#CAFDF5',
  // light: '#61F3F3',
  // main: primary65Opacity,
  main: '#FFFFFF',
  // dark: '#006C9C',
  // darker: '#003768',
  contrastText: '#fff',
} as PaletteColor;

const SUCCESS = {
  // lighter: '#D8FBDE',
  // light: '#86E8AB',
  main: '#2CD365',
  // dark: '#1B806A',
  // darker: '#0A5554',
  contrastText: '#fff',
} as PaletteColor;

const WARNING = {
  // lighter: '#FFF5CC',
  // light: '#FFD666',
  main: '#FF9021',
  // dark: '#B76E00',
  // darker: '#7A4100',
  // contrastText: GREY[800],
  contrastText: '#fff',
} as PaletteColor;

const ERROR = {
  // lighter: '#FFE9D5',
  // light: '#FFAC82',
  main: '#F83D32',
  // dark: '#B71D18',
  // darker: '#7A0916',
  contrastText: '#fff',
} as PaletteColor;

// 卡片背景
const CARD = {
  main: '#232B36',

  contrastText: '#fff',
} as PaletteColor;

const CARDSECONDARY = {
  main: "#333E4F",

  contrastText: '#fff',
} as PaletteColor;  


// const CustomColors = {
//   // 背景
//   defaultBg: '#151718',
//   paperBg: '#202425',
//   // 文本
//   mianText: '#ECEDEE',
//   subText: 'rgba(236, 237, 238, .65)', //二级标题颜色,提示颜色,白底按钮选择
//   lightText: 'rgba(236, 237, 238, .15)', //文本禁用,分割线色调,边框按钮选择
//   // 按钮
//   blueBtn: '#44D7B6',
//   blueHover: 'RGBA(35, 159, 253, 1)',
//   blueSelected: 'RGBA(35, 159, 253, 1)',
//   blueDisabled: 'rgba(0, 145, 255, .15)',
//   // 提示
//   error: '#E02020',
//   warn: '#F7B500',
//   success: '#44D7B6',
// };

const COMMON = {
  // common: { black: CustomColors.defaultBg, white: CustomColors.mianText },
  common: { black: '#151718', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  card: CARD,
  divider: '#323D4D',
  action: {
    // hover: alpha(GREY[500], 0.08),
    // selected: alpha(GREY[500], 0.16),
    // disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha('#0091FF', 0.15),
    // focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.15,
    disabledOpacity: 0.15,
    selectedOpacity: 0.3,
    focusOpacity: 0.3,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const;
  // const light = {} as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: textPrimary,
      secondary: alpha(textPrimary, 0.56),
      disabled: alpha(textPrimary, 0.32),
    },
    background: {
      default: '#1C2026',
      paper: '#232B36',
      neutral: '#333E4F',
      // neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
