import { ActivityDto, BoxDto } from '@/hooks/useActivity';
import { InviteEventsInfoDto } from '@/types';
import { atom } from 'recoil';

export const inviteActivityState = atom<ActivityDto | null>({
  key: 'inviteActivityState',
  default: null,
});

export const inviteEventsInfoState = atom<InviteEventsInfoDto | null>({
  key: 'inviteEventsInfoState',
  default: null,
});

export const boxesState = atom<Array<BoxDto>>({
  key: 'boxesState',
  default: [],
});
