import ICON_COMPLETE from '@/assets/icons/icon_complete.png';
import IMAGE_NO_SIGN from '@/assets/sign/noSignBox.png';
import IMAGE_SIGNBOX7 from '@/assets/sign/signBox7.png';
import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import { alpha, Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
interface SignCardProps {
  day: number;
  dayData?: any;
  dayNum: number;
  maxDayNum?: number;
  todaySign?: number;
  lastSign: number;
  money: number;
  active: boolean;
  small?: boolean;
  linWidth?: string;
  onClick?: () => void;
}

export const SignCardView = ({
  dayData,
  day,
  dayNum,
  lastSign,
  todaySign,
  maxDayNum,
  linWidth,
  onClick,
}: SignCardProps) => {
  const { __ } = useLang();
  const theme = useTheme();

  let activeTmp = dayData.is_sign == 1;
  let today = todaySign == 1 ? lastSign : Number(lastSign) + 1;
  let isToday = today == dayData.day;
  return (
    <Center onClick={onClick} position="relative">
      <Stack alignItems="center" zIndex={10}>
        <Box
          sx={{
            bgcolor: activeTmp ? 'primary.main' : 'background.neutral',
            borderRadius: '100px',
            p: '6px',
          }}
        >
          <img
            src={
              activeTmp
                ? ICON_COMPLETE
                : dayData.weight == 2
                ? IMAGE_SIGNBOX7
                : IMAGE_NO_SIGN
            }
            alt=""
            style={{ width: 16, height: 16 }}
          />
        </Box>

        <Typography
          variant="body2"
          color={alpha(theme.palette.info.main, isToday ? 1 : 0.3)}
          mt={'8px'}
        >
          {isToday
            ? __('Today')
            : __('signDay').replace('{n}', `${dayData.day}`)}
        </Typography>
      </Stack>
    </Center>
  );
};
