import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme';
// Supports weights 400-800
import '@fontsource-variable/baloo-da-2';
import React from 'react';
import { RecoilRoot } from 'recoil';
import Auth from './components/Auth/Auth';
import AppSnackbarProvider, {
  SnackbarUtilsConfigurator,
} from './components/snackbar/SnackbarProvider';

import DataInit from './components/DataInit';
import WithdrawContainer from './layouts/withdarw';
import AppRouters from './routers';

import AudioMp3 from '@/assets/audios/rewards.mp3';
import { Box } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ThemeProvider>
    <RecoilRoot>
      <AppSnackbarProvider>
        <Box position="absolute" top={1000} left={1000}>
          <audio id="reward-audio" src={AudioMp3} autoPlay={false}></audio>
        </Box>
        <SnackbarUtilsConfigurator />
        <BrowserRouter>
          <React.Suspense fallback={<div></div>}>
            <DataInit />
            <Auth></Auth>
            <WithdrawContainer />
            {/* 路由 */}
            <AppRouters />
          </React.Suspense>
        </BrowserRouter>
      </AppSnackbarProvider>
    </RecoilRoot>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
