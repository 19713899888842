import { isOpenVipDialogState } from '@/stores/auth';
import { Box, Dialog } from '@mui/material';
import { useRecoilState } from 'recoil';

import { hideScrollbarY } from '@/utils/cssStyles';
import { Vip } from './vip';

interface Props {}

const VipDialog: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useRecoilState(isOpenVipDialogState);

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '400px',
          maxHeight: 'calc(100vh - 32px)',
          bgcolor: 'card.main',
          ...hideScrollbarY,
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box pb={3} px={2}>
        <Vip open={open} onClose={() => setOpen(false)} />
      </Box>
    </Dialog>
  );
};

export default VipDialog;
