import copyBg from '@/assets/images/codeCopyBg.png';
import codeTip from '@/assets/images/codeTip.png';
import codeTitle from '@/assets/images/codeTitle.png';
import winCodeBg from '@/assets/images/redeeCodeBg.png';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useLang from '@/hooks/useLang';
import { homeTab as homeTabState } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { HomeTab } from '@/types';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export const RedeeCodePage = () => {
  const [homeTab, setHomeTab] = useRecoilState(homeTabState);
  const navigate = useNavigate();
  const search = new URLSearchParams(window.location.search);
  const code = search.get('code') || '';
  const { __ } = useLang();

  const handleCopy = async (textu: string) => {
    console.log('text:', textu);
    const clipboard = navigator.clipboard || {
      writeText: (text) => {
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
      },
    };
    if (clipboard) {
      clipboard.writeText(textu);
      SnackbarUtils.success(__('Copy to clipboard'));
    }
  };

  const handleReceive = async () => {
    navigate('/');
    setHomeTab(HomeTab.Home);
  };

  const width = Math.min(window.innerWidth, globalMaxWidth);

  return (
    <Box
      sx={{
        width: `${width}px`,
        mx: 'auto',
        height: '100vh',
        bgcolor: '#7647EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${winCodeBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: `${width}px`,
          height: '133.33vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={codeTitle}
          alt=""
          style={{ width: '296px', height: '80px', marginTop: '36px' }}
        />
      </Box>

      <Box
        sx={{
          height: '174px',
          width: '343px',
          backgroundImage: `url(${copyBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          mt: '-164px',
          px: 4,
        }}
      >
        <img
          src={codeTip}
          alt=""
          style={{ width: '311px', height: '16px', marginTop: '20px' }}
        />
        <Box
          sx={{
            borderRadius: '8px',
            mt: '16px',
            width: '100%',
            bgcolor: 'common.white',
          }}
        >
          <Stack
            height={'40px'}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              px={1.5}
              variant="body1"
              color={'#501F81'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {code}
            </Typography>

            <IconButton
              size="small"
              color="inherit"
              onClick={() => handleCopy(code)}
              sx={{
                bgcolor: 'primary.main',
                borderRadius: '4px',
                textTransform: 'none',
                mx: '4px',
              }}
              style={{ backgroundColor: '#F1DBF9' }}
            >
              <ContentCopyRoundedIcon sx={{ fontSize: 18, color: '#954BDA' }} />
              <Typography
                variant="body1"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: '#954BDA',
                }}
              >
                {'copy'}
              </Typography>
            </IconButton>
          </Stack>
        </Box>
        <Box
          sx={{
            background: '#E4A833',

            height: '48px',
            borderRadius: 999,
            maxWidth: '350px',
            mx: 'auto',
            cursor: 'pointer',
            mt: '16px',
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(90deg, #FFE767 0%, #EA7D27 100%)',
              height: '44px',
              borderRadius: 999,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={handleReceive}
          >
            <Typography variant="subtitle1" sx={{ lineHeight: '44px' }}>
              {__('Go To Redeem')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width={`${width}px`} px={'16px'} mt={'16px'}>
        <Typography variant="body1" fontWeight={1000}>
          {__('Redemption instructions')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              width: '4px',
              height: '4px',
              borderRadius: '2px',
              mx: '4px',
              mt: '6px',
              bgcolor: 'text.secondary',
            }}
          />
          <Typography variant="body2" fontWeight={500} color={'text.secondary'}>
            {__('codeTip11')}
            <span style={{ fontSize: 12, fontWeight: 1000, color: '#FFD500' }}>
              {1000}
            </span>
            {__('codeTip12')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              width: '4px',
              height: '4px',
              borderRadius: '2px',
              mx: '4px',
              mt: '6px',
              bgcolor: 'text.secondary',
            }}
          />
          <Typography variant="body2" fontWeight={500} color={'text.secondary'}>
            {__('codeTip2')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              width: '4px',
              height: '4px',
              borderRadius: '2px',
              mx: '4px',
              mt: '6px',
              bgcolor: 'text.secondary',
            }}
          />
          <Typography variant="body2" fontWeight={500} color={'text.secondary'}>
            {__('codeTip3')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
