import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { depositActivityState } from '@/stores/config';
import { ActivityType, I18nValue } from '@/types';
import { SetStateAction, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import useAuth from './useAuth';

export interface FirstDepositDto {
  id: string;
  type: number;
  blurb: I18nValue;
  activityTripTimes: string;
  userTripTimes: string;
  title: I18nValue;
  userActivityState: number;
  rules: Array<{
    activityId: string;
    id: string;
    ruleKey: number;
    triggerType: number;
    rules: Array<{
      value: string;
      ruleName: string;
    }>;
    nextRuleValue: string;
    rulePrizeValue: string;
  }>;
}

export interface ActivityPrizeDto {
  activityId: string;
  id: string;
  prizeValue: {
    value: number;
    bonusType?: number;
  };
}

export const useFirstDepositBouns = (setSelectedActivity: {
  (value: SetStateAction<FirstDepositDto | undefined>): void;
  (arg0: any): void;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [details, setDetails] = useRecoilState(depositActivityState);
  const [activityId, setActivityId] = useState<string>('');
  const { __ } = useLang();
  const { isLogin } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      // services.config.getAgentSummary();
      const res = await services.config.getActivityList(
        ActivityType.单次充值活动,
      );
      const activityId = res?.list?.[0]?.id;

      if (!activityId) {
        setLoading(false);
        return;
      }

      setActivityId(activityId);

      try {
        const list = res?.list?.map((item: FirstDepositDto) => {
          return {
            ...item,
            rules: item?.rules?.map((rule, idx) => {
              return {
                ...rule,
                nextRuleValue: item.rules?.[idx + 1]?.rules?.[0]?.value,
              };
            }),
          };
        });

        setDetails(list);
        if (list.length > 0 && setSelectedActivity) {
          if (list[0].userActivityState === 3) {
            SnackbarUtils.warning(__('same tip'), void 0, 2500);
          }
          setSelectedActivity(list[0]);
        }
      } catch (error) {
        setDetails([]);
      }

      setLoading(false);
    };

    fetchData();
  }, [isLogin]);

  return {
    loading,
    details,
    activityId,
  };
};
