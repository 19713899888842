import CommonSkeleton from '@/components/CommonSkeleton';
import useAuth from '@/hooks/useAuth';
import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import { RStatus } from '@/types/vip';
import { hideScrollbarY } from '@/utils/cssStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { VipContent } from './components/VipContent';

export const Vip = ({
  open,
  onClose,
  noHeader,
}: {
  open?: boolean;
  onClose?: () => void;
  noHeader?: boolean;
}) => {
  // const [vip, setVip] = useState<IVip | null>(null);
  // const [loading, setLoading] = useState<boolean>(false);
  const { user, vip, getVipList, updateVipList } = useAuth();
  const homeTabValue = useRecoilValue(homeTab);

  useEffect(() => {
    // const fetchData = async () => {
    //   getVipList();
    //   // const data = await services.config.getVipList();
    //   // if (Array.isArray(data)) {
    //   //   const current = data.find((i) => +i.is_user_vip === 1);
    //   //   setVip(current || null);
    //   // }
    //   // setLoading(false);
    // };
    open && getVipList();
  }, [open]);

  useEffect(() => {
    if (homeTabValue === HomeTab.Vip) {
      updateVipList();
    }
  }, [homeTabValue]);

  const Header = (
    <Box
      sx={{
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box width="40px"></Box>
      <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
        VIP
      </Typography>
      <Box width="40px">
        <IconButton color="info" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );

  const LoadingView = <CommonSkeleton />;

  const isLoading = vip.status !== RStatus.success || !user;
  return (
    <Stack spacing={1}>
      {noHeader ? null : Header}
      <Box
        sx={{
          maxHeight: 'calc(100vh - 116px)',
          // mr: '-16px !important',
          ...hideScrollbarY,
        }}
      >
        {isLoading ? (
          LoadingView
        ) : (
          <VipContent noHeader={noHeader} user={user} />
        )}
      </Box>
    </Stack>
  );
};
