// 需要登录的页面路由
// 请在此处添加需要登录的页面路由
export const authPages = [
  '/invite',
  '/lucky',
  '/winnings',
  '/me',
  '/records',
  '/setting',
  '/account',
  '/set-payment',
  '/edit-payment',
  '/edit-login',
  '/deposit',
  '/vip',
  '/commissions',
  'invite-box',
  '/wallet',
];
