import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ICON_GIFT from '../../assets/icons/icon-gifts.png';
import CommonTitleHead from '../../components/CommonTitleHead';
import ProcessBar from '../../components/process-bar';
import useScrollToTop from '../../hooks/useScrollToTop';

interface Props {}

const Activity: React.FC<Props> = (props: Props) => {
  useScrollToTop();
  const navigate = useNavigate();
  return (
    <Box>
      <CommonTitleHead title="Activity" onBack={() => navigate(-1)} />
      <Box>
        <Stack spacing={2} direction={{ xs: 'column' }}>
          <Box
            sx={{
              flex: 1,
              bgcolor: '#221C3F',
              borderRadius: '10px',
              minHeight: '208px',
              pt: '16px',
              textAlign: 'center',
            }}
          >
            <Stack justifyContent="center" direction="row">
              <Box sx={{ width: { xs: 24 } }}>
                <img src={ICON_GIFT} alt="" style={{ width: '100%' }} />
              </Box>
            </Stack>
            <Typography mt="5px" variant="body1">
              Single Deposit
            </Typography>
            <Typography
              px="42px"
              mt="12px"
              variant="body2"
            >{`A single deposit>=100 can get addition rewards. Limit 3 times per day.`}</Typography>
            <Typography mt="28px" variant="body2">
              Oportunidade Diaria
            </Typography>
            <Box px="28px">
              <ProcessBar size="small" percent={33} label="1/3" />
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              bgcolor: '#221C3F',
              borderRadius: '10px',
              minHeight: '208px',
              pt: '16px',
              textAlign: 'center',
            }}
          >
            <Stack justifyContent="center" direction="row">
              <Box sx={{ width: { xs: 24 } }}>
                <img src={ICON_GIFT} alt="" style={{ width: '100%' }} />
              </Box>
            </Stack>
            <Typography mt="5px" variant="body1">
              Rewards Single Deposit
            </Typography>
            <Typography
              px="42px"
              mt="12px"
              variant="body2"
            >{`A single deposit>=100`}</Typography>
            <Typography mt="28px" variant="body2">
              Oportunidade Diaria
            </Typography>
            <Box px="28px">
              <ProcessBar size="small" percent={0} label="0/3" />
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              bgcolor: '#221C3F',
              borderRadius: '10px',
              minHeight: '208px',
              pt: '16px',
              textAlign: 'center',
            }}
          >
            <Stack justifyContent="center" direction="row">
              <Box sx={{ width: { xs: 24 } }}>
                <img src={ICON_GIFT} alt="" style={{ width: '100%' }} />
              </Box>
            </Stack>
            <Typography mt="5px" variant="body1">
              Single Deposit
            </Typography>
            <Typography
              px="42px"
              mt="12px"
              variant="body2"
            >{`A single deposit>=100 can get addition rewards. Limit 3 times per day.`}</Typography>
            <Typography mt="28px" variant="body2">
              Oportunidade Diaria
            </Typography>
            <Box px="28px">
              <ProcessBar size="small" percent={100} label="3/3" />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Activity;
