import { ActivityDto } from '@/hooks/useActivity';
import { Box, Stack, Typography } from '@mui/material';

export const InviteWinningMoney = (props: { detail: ActivityDto | null }) => {
  return (
    <Box
      p={2}
      mt={{
        xs: 0,
        md: 1,
      }}
    >
      <Box
        sx={{
          background: 'linear-gradient(180deg, #36A8F4 0%, #18457C 100%)',
          py: 2,
          px: 1,
          borderRadius: '6px',
        }}
        textAlign="center"
      >
        <Stack direction="row" alignItems="center">
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography variant="h1" className="futura-font">
              {props.detail?.userActivity?.completeRules?.[0]?.currentValue ??
                0}
            </Typography>
            <Typography
              whiteSpace="nowrap"
              variant="body1"
              color="text.secondary"
            >
              Successfully invited
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography variant="h1" className="futura-font">
              <span style={{ fontSize: 20, marginRight: 4, fontWeight: 400 }}>
                R$
              </span>
              {props.detail?.userActivity?.receivedAmt ?? 0}
            </Typography>
            <Typography
              whiteSpace="nowrap"
              variant="body1"
              color="text.secondary"
            >
              Bounty received
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
