import { useRecoilState } from 'recoil';
import { en, pt } from '../locales';
import { langState, languageState } from '../stores/lang';
import { I18nValue, LanguageType } from '../types';

const useLang = () => {
  const [lang, setLang] = useRecoilState(langState);
  const [language, setLanguage] = useRecoilState(languageState);
  const __ = (key: string) => {
    return lang[key] || key;
  };

  const modifyLanguage = (val: LanguageType) => {
    setLang(val === LanguageType.EN ? en : pt);
    setLanguage(val);
    localStorage.setItem('__language__', val);
  };

  const getValueByLanguage = (value: I18nValue) => {
    if (!value) {
      return '';
    }

    if (language === LanguageType.PT) {
      return value.ptBR ?? '';
    }
    return value.enUS ?? '';
  };

  return {
    __,
    lang,
    language,
    isEn: language === LanguageType.EN,
    modifyLanguage,
    getValueByLanguage,
  };
};

export default useLang;
