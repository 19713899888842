import services from '@/services';
import { useEffect, useState } from 'react';

export interface AgentListItemDto {
  commission: number;
  id: number | string;
  nickname: string;
  poll: number;
  reg_time: number;
  status: number;
}

export interface AgentTotalDto {
  all_num: number;
  commission: number;
  waiting_num: number;
  yes_num: number;
}

export const useAgentList = (start: string, end: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<AgentListItemDto[]>([]);
  const [total, setTotal] = useState<AgentTotalDto | null>(null);

  useEffect(() => {
    const fetchAgentSummary = async () => {
      setIsLoading(true);

      const { list, total } = await services.auth.getMembers({
        page: 1,
        pagesize: 200,
        total: 1,
        start_time: `${start} 00:00:00`,
        end_time: `${end} 23:59:59`,
      });
      setList(list);
      setIsLoading(false);
      setTotal(total);
    };

    fetchAgentSummary();
  }, [start, end]);

  return {
    list,
    total,
    isLoading,
  };
};
