import IMAGE_BACK from '@/assets/buttons/back-button.png';
import EmptyData from '@/components/EmptyData';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { ColorfulBg } from '@/styles';
import { globalMaxWidth } from '@/theme';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {}

const GamePlayPage: React.FC<Props> = (props: Props) => {
  const navigete = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [gameUrl, setGameUrl] = useState<string>('');
  const [open, setOpen] = useBoolean();
  const { __ } = useLang();
  const { getWallet } = useAuth();
  const gameRef = useRef<HTMLIFrameElement | null>(null);

  const [width, setWidth] = useState<number>(
    Math.min(window.innerWidth, globalMaxWidth),
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (id) {
      getGameUrl(id);
    }
  }, []);

  useEffect(() => {
    if (gameRef.current) {
      gameRef.current.style.height = window.innerHeight + 'px';
    }
  }, [gameUrl]);

  useEffect(() => {
    function handleResize() {
      if (gameRef.current) {
        gameRef.current.style.height = window.innerHeight + 'px';
      }

      setWidth(Math.min(window.innerWidth, globalMaxWidth));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleOrientationChange() {
      if (gameRef.current) {
        gameRef.current.style.height = window.innerHeight + 'px';
      }
    }

    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  const handleBack = async () => {
    setOpen.off();

    setLoading(true);
    let Res = await getWallet();
    setLoading(false);
    navigete(-1);
    // setTimeout(() => {
    //   getWallet();
    // }, 3000);
  };

  const getGameUrl = async (id: string) => {
    const res = await services.game.getGameUrl({ game_id: +id });
    if (res?.data) {
      setGameUrl(res?.data);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: `${width}px`,
          height: '100%',
          minHeight: '100%',
          maxHeight: '100vh',
          position: 'relative',
        }}
      >
        {/* 返回按钮 */}
        <IconButton
          sx={{
            position: 'absolute',
            top: '40px',
            left: '12px',
          }}
          color="default"
          onClick={() => {
            setOpen.on();
          }}
        >
          <img src={IMAGE_BACK} alt="back" width={36} height={36} />
          {/* <WestRoundedIcon sx={{ color: 'white' }} /> */}
        </IconButton>

        <Center
          py={10}
          style={{
            width: `${width}px`,
            height: '100vh',
            verticalAlign: 'middle',
            display: !loading ? 'none' : 'flex',
          }}
        >
          <CircularProgress />
        </Center>

        {!!gameUrl ? (
          <iframe
            onLoad={() => {
              setLoading(false);
            }}
            ref={gameRef}
            title="game"
            src={gameUrl}
            frameBorder={0}
            id="gameIframe"
            style={{
              width: `${width}px`,
              height: '100vh',
              verticalAlign: 'middle',
              display: loading ? 'none' : 'flex',
            }}
          ></iframe>
        ) : (
          <Center py={10}>
            <EmptyData />
          </Center>
        )}
      </Box>
      {/* 退出弹窗 */}
      <Dialog onClose={setOpen.off} open={open}>
        {/* <CloseButton onClick={setOpen.off} /> */}
        <Box
          py={3}
          px={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle1" fontWeight={800}>
            {__('Are you sure to exit?')}
          </Typography>
        </Box>

        <Box px={2} pb={2}>
          <Stack width="100%" direction="row" alignItems="center" spacing={1.5}>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              onClick={setOpen.off}
              sx={{
                height: '48px',
                width: '132px',
                color: '#ffffff',
                borderColor: '#323D4D',
              }}
            >
              {__('Cancel')}
            </Button>
            <Button
              fullWidth
              sx={{ background: ColorfulBg(), height: '48px', width: '132px' }}
              variant="contained"
              size="small"
              onClick={handleBack}
            >
              {__('Sure')}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};

export default GamePlayPage;
