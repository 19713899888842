import useResponsive from '@/hooks/useResponsive';
import DepositPage from '@/pages/Wallet/Deposit';
import { isOpenDepositState } from '@/stores/auth';
import { globalMaxWidth } from '@/theme';
import { Box, SwipeableDrawer } from '@mui/material';
import { useRecoilState } from 'recoil';

interface Props {}

const DipositDialog: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useRecoilState(isOpenDepositState);
  const isMobile = useResponsive('down', 'md');

  const handleClose = () => {
    setOpen(false);
  };

  // if (isMobile) {
  // }
  return (
    <SwipeableDrawer
      open={open}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      anchor={isMobile ? 'bottom' : 'right'}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: '24px 24px 0 0',
          height: 'calc(100vh - 32px)',
          bgcolor: 'card.main',
          width: '100%',
          maxWidth: globalMaxWidth,
          left: {
            xs: 0,
            sm: `calc(50% - ${globalMaxWidth / 2}px)`,
          },
          // transform: 'translateX(-50%) !important',
        },
      }}
      ModalProps={{
        container: document.getElementById('root') as HTMLElement,
      }}
    >
      <Box
        height="100%"
        sx={{
          width: '100%',
          maxWidth: globalMaxWidth,
          mx: 'auto',
        }}
      >
        {open ? (
          <DepositPage
            type="dialog"
            height="calc(100% - 120px)"
            onClose={handleClose}
          />
        ) : null}
      </Box>
    </SwipeableDrawer>
  );

  // return (
  //   <Dialog
  //     open={open}
  //     PaperProps={{
  //       sx: {
  //         width: '400px',
  //         maxWidth: '400px',
  //         borderRadius: '20px 20px 0 0',
  //         maxHeight: 'calc(100vh - 56px)',
  //         ...hideScrollbarY,
  //       },
  //     }}
  //     onClose={handleClose}
  //     aria-labelledby="sign-in"
  //   >
  //     <Box bgcolor="background.neutral">
  //       {open ? <DepositPage type="dialog" onClose={handleClose} /> : null}
  //     </Box>
  //   </Dialog>
  // );
};

export default DipositDialog;
