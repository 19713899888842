import {
  ActivityPrizeDto,
  FirstDepositDto,
} from '@/hooks/useFirstDepositBouns';

export const findRuleByValue = (
  value: number,
  rules?: FirstDepositDto['rules'],
) => {
  if (!rules || rules.length === 0) {
    return null;
  }

  if (!value) {
    return null;
  }

  const target = rules.find((rule) => {
    const ruleValue = rule.rules?.[0]?.value ?? '';
    if (rule.nextRuleValue) {
      return +ruleValue <= +value && +value < +rule.nextRuleValue;
    } else {
      return +ruleValue <= +value;
    }
  });

  if (target) {
    return target;
  }

  if (value >= +(rules?.[rules.length - 1]?.rules?.[0]?.value ?? 0)) {
    return rules[rules.length - 1];
  }
  return null;
};

export const getBounsValue = (prizeList?: Array<ActivityPrizeDto>) => {
  if (!prizeList || prizeList.length === 0) {
    return '';
  }

  let res: string[] = [];

  prizeList.forEach((prize) => {
    if (prize.prizeValue.bonusType === 1) {
      res.push(`${prize.prizeValue.value}%`);
    } else {
      res.push(`${prize.prizeValue.value}`);
    }
  });

  return res.join('~');
};
