import useLang from '@/hooks/useLang';
import MePage from '@/pages/Me';
import { meDrawerState } from '@/stores/auth';
import { Box, Drawer } from '@mui/material';
import { useRecoilState } from 'recoil';

interface Props {}

enum RecordType {}

const MeDrawer = (props: Props) => {
  const { __ } = useLang();
  const [openMeDrawer, setOpenMeDrawer] = useRecoilState(meDrawerState);

  const _onClose = () => {
    setOpenMeDrawer(false);
  };

  return (
    <Drawer
      anchor={'top'}
      open={openMeDrawer}
      // onOpen={() => setOpenWithdraw(true)}
      onClose={_onClose}
      ModalProps={{
        container: document.getElementById('root') as HTMLElement,
      }}
    >
      <Box
        position="relative"
        style={{
          width: '100vw',
          height: '100vh',
        }}
        sx={{ bgcolor: 'background.default', px: '16px' }}
      >
        <MePage />
      </Box>
    </Drawer>
  );
};

export default MeDrawer;
