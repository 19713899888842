import DEFAULT_AVATAR from '@/assets/images/avatar-default.png';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import {
  isLoginState,
  isOpenLRModalState,
  LRModalInitalPage,
  meDrawerState,
} from '@/stores/auth';
import { supportListState, tenantConfigState } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import {
  Box,
  Button,
  ClickAwayListener,
  Menu,
  Skeleton,
  Stack,
} from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Center from '../../components/center';
import useResponsive from '../../hooks/useResponsive';
import MeDrawer from '../../pages/Me/MeDrawer';
import BalanceBar from './BalanceBar';
import UserInfo from './UserMenu/UserInfo';
import UserMenus from './UserMenu/UserMenus';
import WalletBox from './UserMenu/WalletBox';

const SHOW_DOWNLOAD_PATHS = ['/me'];

const ProfileSection = () => {
  const isLogin = useRecoilValue(isLoginState);
  const isMobile = useResponsive('down', 'md');
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const supportList = useRecoilValue(supportListState);

  const { __ } = useLang();
  const location = useLocation();
  const tenantConfig = useRecoilValue(tenantConfigState);
  const isHideMobleMoney =
    isMobile && SHOW_DOWNLOAD_PATHS.includes(location.pathname);
  let liveSupport = supportList.find((su) => +su.type === 5);

  if (!isLogin) {
    return (
      <Stack direction="row" spacing={1.5}>
        {tenantConfig?.iconConfig?.logoIcon?.navLogo ? (
          <>
            <Button
              onClick={() => {
                // if (isMobile) {
                //   navigate('/signup');
                // } else {
                //   // setOpenSignupModal(true);
                //   setInitalPage(1);
                //   setOpenLRModal(true);
                // }
                setInitalPage(0);
                setOpenLRModal(true);
              }}
              variant="text"
              // sx={{ height: '24px' }}
              size="small"
              sx={{
                color: '#fff',
                background: ColorfulBg(),
                borderRadius: '5px',
                minWidth: '80px',
              }}
            >
              {__('Login or Register')}
            </Button>
            <LiveSupportButton />
          </>
        ) : (
          <Skeleton
            variant="rounded"
            width={100}
            height={32}
            sx={{ mt: '20px' }}
          />
        )}
      </Stack>
    );
  }

  return (
    <Box>
      <Stack alignItems="center" spacing={2} direction="row">
        {/* 去掉入口 */}
        {/* {isMobile ? null : <InviteBoxButton size={40} />} */}
        <BalanceBar />
        {/* {isMobile ? <HeaderDownLoad /> : null} */}
        <LiveSupportButton />
        {!isMobile ? <UserButton /> : null}
      </Stack>
    </Box>
  );
};

export const UserButton = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useBoolean();
  const location = useLocation();
  const setOpenMeDrawer = useSetRecoilState(meDrawerState);
  const isMobile = useResponsive('down', 'md');
  const handleClose = () => {
    setOpen.off();
  };

  useEffect(() => {
    if (open) {
      setOpen.off();
    }
  }, [location]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box ref={anchorRef}>
        <Center
          sx={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          onClick={() => {
            isMobile ? navigate('/me') : setOpen.on(); ///*setOpenMeDrawer(true)*/
          }}
          aria-controls={open ? 'composition-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <img
            src={user?.avatar || DEFAULT_AVATAR}
            alt=""
            style={{
              width: 32,
              height: 32,
              borderRadius: '50%',
              verticalAlign: 'middle',
              overflow: 'hidden',
            }}
          />
        </Center>
        <MeDrawer />
        <Menu
          id="basic-menu"
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'right',
          // }}
          // transformOrigin={{
          //   vertical: -28,
          //   horizontal: 'right',
          // }}
          PaperProps={{
            sx: {
              p: 2,
              bgcolor: 'background.paper',
            },
          }}
        >
          <Stack spacing={1}>
            <UserInfo />
            <WalletBox />
            <UserMenus />
          </Stack>
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default ProfileSection;

const LiveSupportButton = () => {
  const supportList = useRecoilValue(supportListState);
  let liveSupport = supportList.find((su) => +su.type === 5);

  if (!liveSupport) {
    return null;
  }

  return (
    <img
      onClick={(event) => {
        event.stopPropagation();
        // window.location.href = liveSupport.url;
        window.open(liveSupport?.url, '_blank');
      }}
      src={liveSupport.qr}
      alt=""
      style={{
        width: 30,
        height: 30,
        objectFit: 'cover',
      }}
    />
  );
};
