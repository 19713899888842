import { IconCoupon } from '@/components/iconify/IconCoupon';
import { IDepositCoupon } from '@/types/config';
import { calculateTime, differenceInSeconds, fixZore } from '@/utils';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useCountdown } from 'usehooks-ts';

export const Coupon = (props: {
  isSelect?: boolean;
  data: IDepositCoupon;
  onSelect: () => void;
}) => {
  const now = new Date();
  const end = new Date(+props.data.out_time * 1000);
  return (
    <Box
      sx={{
        width: '140px',
        height: '64px',
        position: 'relative',
        borderRadius: 1,
        minWidth: '140px',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      onClick={props.onSelect}
    >
      <IconCoupon color="#36A8F4" />
      <ActiveTime time={differenceInSeconds(now, end)} />
      <Bouns bouns={props.data.max_reward_money} />
      <Amount amount={props.data.min_money} />
      <CheckIcon isCheck={props.isSelect} />
    </Box>
  );
};

const ActiveTime = ({ time }: { time: number }) => {
  const [count, { startCountdown }] = useCountdown({
    countStart: time,
    intervalMs: 1000,
  });

  useEffect(() => {
    startCountdown();
  }, []);

  const { days, hours, minutes, seconds } = calculateTime(count);
  let label = `${fixZore(hours)}:${fixZore(minutes)}:${fixZore(seconds)}`;
  if (days) {
    label = `${days}d ${label}`;
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        bgcolor: 'text.disabled',
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </Box>
  );
};

const Bouns = ({ bouns }: { bouns: number }) => {
  if (bouns <= 0) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        px: '4px',
        py: '2px',
        bgcolor: 'text.disabled',
        borderRadius: '0 8px',
        fontSize: '12px',
        lineHeight: 1,
      }}
    >
      +{bouns}
    </Box>
  );
};

const Amount = ({ amount }: { amount: number }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '20px',
        left: 0,
        right: 0,
        width: '100%',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography>R$</Typography>
        <Typography variant="h2" fontWeight={700}>
          {amount}
        </Typography>
      </Stack>
    </Box>
  );
};

const CheckIcon = ({ isCheck }: { isCheck?: boolean }) => {
  if (!isCheck) return null;
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '4px',
        left: '4px',
        height: '16px',
        width: '16px',
        bgcolor: 'secondary.main',
        borderRadius: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckRoundedIcon sx={{ fontSize: 12 }} />
    </Box>
  );
};
