import useLang from '@/hooks/useLang';
import { InviteEventsInfoDto } from '@/types';
import { Box, Divider, Typography } from '@mui/material';

export const InviteRules = (props: { info: InviteEventsInfoDto | null }) => {
  const { __, language } = useLang();
  return (
    <Box px={{ xs: 1.5 }} py={{ xs: 0 }}>
      {/* <Typography variant="subtitle2">{__('Mission conditions')}</Typography> */}
      <Box
        sx={{
          background: '#313D4D',
          borderRadius: 1,
          px: 2,
          py: 0.5,
          fontStyle: 'italic',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: { xs: '32px' },
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            {__('recharge reaches')}
          </Typography>
          <Typography variant="subtitle2">
            R${props.info?.vaildInviteeAmt ?? 0}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.16)' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: { xs: '32px' },
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            {__('Cumulative bets reached')}
          </Typography>
          <Typography variant="subtitle2">
            {props.info?.vaildInviteeOrderAmt ?? 0}
          </Typography>
        </Box>
      </Box>
      {/* <Typography variant="body1" color="text.secondary" mt={1}>
        {__('boxTip1')}
      </Typography>
      <Typography variant="body1" color="text.secondary" mt={1}>
        {__('boxTip2')}
      </Typography> */}
    </Box>
  );
};
