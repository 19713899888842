import IMAGE_FB from '@/assets/images/Facebook.png';
import IMAGE_GL from '@/assets/images/Google.png';
import IMAGE_SK from '@/assets/images/Skype.png';
import IMAGE_WA from '@/assets/images/Whatsapp.png';
import { IconButton } from '@mui/material';

interface Props {
  type: 'facebook' | 'google' | 'skype' | 'whatsapp';
}

const SRC_MAP = {
  facebook: IMAGE_FB,
  google: IMAGE_GL,
  skype: IMAGE_SK,
  whatsapp: IMAGE_WA,
};

const SocialButton: React.FC<Props> = (props: Props) => {
  return (
    <IconButton
      size="large"
      sx={{
        bgcolor: 'background.neutral',
      }}
    >
      <img
        src={SRC_MAP[props.type]}
        alt="facebook"
        style={{ width: '24px', height: '24px' }}
      />
    </IconButton>
  );
};

export default SocialButton;
