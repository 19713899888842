import ErrorIcon from '@/assets/icons/snake_fail.png';
import SuccessIcon from '@/assets/icons/snake_success.png';
import useLang from '@/hooks/useLang';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  list: any[];
}

/**
 * 提现弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const RedeemFailDrawerConten = (props: Props) => {
  const { __ } = useLang();
  return (
    <Box sx={{ width: '100%', pb: { xs: 2, md: 0 } }} pb={'8px'}>
      <Box px={2.5} pt={2.5} pb={4}>
        <Typography variant="body1" color={'text.primary'} mb={'16px'}>
          {__('Redeem when conditions are met')}
        </Typography>
        <Stack
          sx={{
            borderRadius: '8px',
            px: '16px',
            border: { xs: '1px solid #323D4D', md: '1px solid #323D4D' },
          }}
        >
          {props.list.map((item, index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  minHeight: '56px',
                  py: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#323D4D',
                }}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    color={'text.primary'}
                    fontWeight={500}
                  >
                    {item.ruleName}
                  </Typography>
                  <Typography variant="body2" color={'text.secondary'}>
                    {`${item.currentValue}/${item.targetValue}`}
                  </Typography>
                </Box>
                <img
                  src={
                    parseInt(item?.currentValue + '' || '0', 10) >=
                    parseInt(item?.targetValue + '' || '0', 10)
                      ? SuccessIcon
                      : ErrorIcon
                  }
                  alt=""
                  width={20}
                  height={20}
                  style={{ marginInlineEnd: 8 }}
                />
              </Box>
            );
          })}

          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '56px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography>{'top up'}</Typography>
              <Typography color={'text.secondary'}>{'R$1000/1,000'}</Typography>
            </Box>
            <img
              src={SuccessIcon}
              alt=""
              width={20}
              height={20}
              style={{ marginInlineEnd: 8 }}
            />
          </Box> */}
        </Stack>
      </Box>
    </Box>
  );
};
const styles = {
  input: {
    height: '48px',
    bgcolor: 'card.main',
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'divider', // 设置边框颜色
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'divider', // 设置禁用状态下的边框颜色
      },
    },
  },
};
export default RedeemFailDrawerConten;
