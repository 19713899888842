import DEFAULT_AVATAR from '@/assets/images/avatar-default.png';

import VIP0 from '@/assets/vip/VIP_l0.png';
import VIP1 from '@/assets/vip/VIP_l1.png';
import VIP10 from '@/assets/vip/VIP_l10.png';
import VIP2 from '@/assets/vip/VIP_l2.png';
import VIP3 from '@/assets/vip/VIP_l3.png';
import VIP4 from '@/assets/vip/VIP_l4.png';
import VIP5 from '@/assets/vip/VIP_l5.png';
import VIP6 from '@/assets/vip/VIP_l6.png';
import VIP7 from '@/assets/vip/VIP_l7.png';
import VIP8 from '@/assets/vip/VIP_l8.png';
import VIP9 from '@/assets/vip/VIP_l9.png';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import { alpha, Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface Props {}

const UserInfo: React.FC<Props> = (props: Props) => {
  const { user } = useAuth();
  const { __ } = useLang();
  const navigate = useNavigate();

  const theme = useTheme();

  const getVipImg = () => {
    switch (user?.level) {
      case 0:
        return VIP0;
      case 1:
        return VIP1;
      case 2:
        return VIP2;
      case 3:
        return VIP3;
      case 4:
        return VIP4;
      case 5:
        return VIP5;
      case 6:
        return VIP6;
      case 7:
        return VIP7;
      case 8:
        return VIP8;
      case 9:
        return VIP9;
      case 10:
        return VIP10;
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="start"
      spacing={1.5}
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '56px',
          height: '56px',
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={user.avatar || DEFAULT_AVATAR}
          alt=""
          style={{ width: 56, height: 56, objectFit: 'cover' }}
        />
      </Box>
      {/* <Box>
        <Typography
          onClick={() => navigate('/account')}
          variant="subtitle2"
          fontWeight={700}
        >
          {user.nick_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {__('ID: ')}
          {user.uid}
        </Typography>
      </Box> */}
      <Box>
        <Stack flexDirection={'row'} alignItems="center">
          <Typography
            onClick={() => navigate('/me')}
            variant="h2"
            fontWeight={700}
          >
            {user.nick_name}
          </Typography>
          <img
            src={getVipImg()}
            alt=""
            style={{ width: 58, height: 16, marginLeft: '4px' }}
          />
        </Stack>

        <Typography
          mt={'8px'}
          variant="subtitle2"
          color={alpha(theme.palette.info.main, 0.56)}
        >
          {__('ID: ')}
          {user.uid}
        </Typography>
      </Box>
    </Stack>
  );
};

export default UserInfo;
