import useLang from '@/hooks/useLang';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { useState } from 'react';

const TabStyle = {
  '&:not(.Mui-selected)': {
    color: 'text.disabled',
  },
};

interface Props {
  emailTab: React.ReactNode;
  phoneTab: React.ReactNode;
}

const AuthTabs: React.FC<Props> = (props: Props) => {
  const [tab, setTab] = useState<'phone' | 'email'>('phone');
  const { __ } = useLang();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: 'phone' | 'email',
  ) => {
    setTab(newValue);
  };

  return (
    <TabContext value={tab}>
      <TabList
        TabIndicatorProps={{
          sx: {
            // background: 'linear-gradient(90deg, #B304FE 0%, #0CC0F7 100%)',
            bgcolor: 'primary.main',
            height: '3px',
            borderRadius: '9px',
          },
        }}
        textColor="inherit"
        onChange={handleChange}
        sx={{
          '.MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
        }}
      >
        <Tab sx={TabStyle} label={__('Phone')} value="phone" />
        <Tab sx={TabStyle} label={__('Email')} value="email" />
      </TabList>
      <TabPanel sx={{ px: 0, py: '20px' }} value="phone">
        {props.phoneTab}
      </TabPanel>
      <TabPanel sx={{ px: 0, py: '20px' }} value="email">
        {props.emailTab}
      </TabPanel>
    </TabContext>
  );
};

export default AuthTabs;
