import { useActivityScrollrMessages } from '@/hooks/useActivity';
import useLang from '@/hooks/useLang';
import { DepositScrollMessageList } from '@/pages/Commissions/components/ScrollMessageList';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';

interface Props {
  onClose?: () => void;
  type?: number;
}

const DepositDialogHeader: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const { messages, loading } = useActivityScrollrMessages(props.type);

  const originalTitle = (
    <>
      <Box width="40px" height="40px"></Box>
      <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
        {__('Deposit')}
      </Typography>
    </>
  );

  return (
    <Box
      sx={{
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: {
          xs: 'card.main',
          md: 'background.neutral',
        },
      }}
    >
      {props.type === undefined || !messages.length ? (
        originalTitle
      ) : loading ? (
        <Box height="36px" width="100%" pl={2}>
          <Skeleton variant="rectangular" width="100%" height="36px" />
        </Box>
      ) : (
        <Box
          sx={{
            width: 'calc(100% - 40px)',
          }}
        >
          <DepositScrollMessageList list={messages} />
        </Box>
      )}

      <Box width="40px">
        <IconButton color="info" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DepositDialogHeader;
