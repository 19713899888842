import useLang from '@/hooks/useLang';
import { LanguageType } from '@/types';
import { Box, Typography } from '@mui/material';

interface Props {}

/**
 * 弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const NumTipDrawerConten = (props: Props) => {
  const { __ } = useLang();
  const { language } = useLang();
  return (
    <Box sx={{ width: '100%', py: 3, px: 2 }} pb={'8px'}>
      <Typography variant="body1">{__('Box Tip')}</Typography>
      {language === LanguageType.PT ? (
        <>
          <br />
          <Typography variant="body1">{__('Box Tip1')}</Typography>
          <Typography variant="body1">{__('Box Tip2')}</Typography>
          <Typography variant="body1">{__('Box Tip3')}</Typography>
        </>
      ) : null}
    </Box>
  );
};

export default NumTipDrawerConten;
