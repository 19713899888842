import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Box, IconButton, Typography } from "@mui/material";

interface Props {
  title: string;
  onBack?: () => void;
}

const MobileTitleHead: React.FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        height: 44,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton onClick={props.onBack} sx={{ position: "absolute", left: 4 }}>
        <ArrowBackIosRoundedIcon fontSize="small" />
      </IconButton>
      <Typography textAlign="center" variant="h4">
        {props.title}
      </Typography>
    </Box>
  );
};

export default MobileTitleHead;
