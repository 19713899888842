import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import Center from '@/components/center';
import { Box, Dialog, IconButton, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  pic?: string;
  title?: string;
  num?: number;
}

const RewardDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box>
        <Box bgcolor="background.paper" py={4} px="90px" borderRadius="16px">
          <Center>
            <img
              src={props.pic}
              alt=""
              style={{
                width: 120,
                height: 120,
                border: '1px solid #333E4F',
                backgroundColor: 'background.paper',
                borderRadius: '16px',
              }}
            />
          </Center>
          <Typography
            mt={1}
            textAlign="center"
            variant="body1"
            fontWeight={500}
          >
            {props.title}
          </Typography>
          {/* <Typography
            textAlign="center"
            variant="body2"
            color={'text.secondary'}
          >
            {`x${props.num}`}
          </Typography> */}
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={props.onClose}>
            {/* <CloseRoundedIcon /> */}
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RewardDialog;
