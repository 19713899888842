import useLang from '@/hooks/useLang';
import { Stack, Typography } from '@mui/material';
import IMAGE_EMPTY from '../assets/images/empty.png';
interface Props {
  text?: string;
}

const EmptyData: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  return (
    <Stack alignItems="center" spacing={1} sx={{ color: '#39325D' }}>
      <img src={IMAGE_EMPTY} alt="" style={{ width: '46px', height: '46px' }} />
      <Typography variant="subtitle1" color={'text.secondary'}>
        {props.text || __('Empty')}
      </Typography>
    </Stack>
  );
};

export default EmptyData;
