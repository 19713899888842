import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { ColorfulBg } from '@/styles';
import { alpha, useTheme } from '@mui/material/styles';

// import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';

interface Props {
  onUnbind: () => void;
  id: string;
}

const UnBindButton: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useBoolean();
  // const [openResult, setOpenResult] = useBoolean();
  // const [isSuccess, setIsSuccess] = useBoolean(true);
  const [loading, setLoading] = useBoolean(false);
  const { getUserInfo } = useAuth();
  const { __ } = useLang();
  const theme = useTheme();

  const handleUnbind = async () => {
    if (loading) return;
    setLoading.on();
    await services.auth.unbind({ id: props.id });
    SnackbarUtils.success(__('解绑成功'));
    getUserInfo();
    setLoading.off();
    setOpen.off();
    props.onUnbind();
  };

  // const handleUnbind = () => {
  //   setOpenResult.on();
  //   setOpen.off();
  // }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={setOpen.on}
        sx={{
          borderRadius: '50px',
          color: '#fff',
          bgcolor: alpha(theme.palette.grey[100], 0.32),
          borderWidth: 0,
        }}
      >
        {__('解绑')}
      </Button>
      <Dialog onClose={setOpen.off} open={open}>
        <Box py={{ xs: 8 }} px={10}>
          <Typography variant="subtitle1" fontWeight={{ xs: 800 }}>
            {__('是否确定解绑？')}
          </Typography>
        </Box>

        <Box px={1.5} pb={1.5}>
          <Stack width="100%" direction="row" alignItems="center" spacing={1.5}>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              color="info"
              sx={{ borderRadius: 999, borderColor: 'text.disabled' }}
              onClick={setOpen.off}
            >
              {__('Cancel')}
            </Button>
            <LoadingButton
              fullWidth
              sx={{ background: ColorfulBg(), borderRadius: 999 }}
              variant="contained"
              size="small"
              onClick={handleUnbind}
              loading={loading}
            >
              {__('Sure')}
            </LoadingButton>
          </Stack>
        </Box>
      </Dialog>

      {/* <Dialog onClose={setOpenResult.off} open={openResult}>
        <CloseButton onClick={setOpenResult.off} />
        <Box pt={6} px={12}>
          {isSuccess ? (
            <CheckCircleRoundedIcon
              sx={{ fontSize: 80, color: 'success.main' }}
            />
          ) : (
            <CancelRoundedIcon sx={{ fontSize: 80, color: 'error.main' }} />
          )}
        </Box>
        <Box pt={4} pb={4}>
          <Typography variant="subtitle2" textAlign="center" fontWeight={700}>
            {isSuccess ? '解绑成功' : '解绑失败'}
          </Typography>
        </Box>
      </Dialog> */}
    </>
  );
};

export default UnBindButton;
