export enum RStatus {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  error = 'error',
}
export interface IVipItem {
  id: number; //VIP等级ID
  title: string; //VIP等级名称
  pic: string; //VIP等级图标
  level:number;//vip等级
  update_dama: string; //升至该VIP等级打码量要求
  update_money: string; //升至该VIP等级充值总额要求
  update_money_num: string; //升至该VIP等级充值次数要求
  update_bonus: string; //升至该VIP等级可获得的奖励金
  update_bonus_dama: string; //升至该VIP等级获得的奖励金打码量要求
  protect_money: string; //保级充值金额
  protect_dama: string; //保级打码量
  protect_day: string; //保级天数，升级后指定保级天数内未完成保级任务将被降级
  day_money: string; //日充值限额（为0可不显示）
  week_money: string; //周充值限额（为0可不显示）
  moon_money: string; //月充值限额（为0可不显示）
  money_commission: string; //充值手续费比例（单位%，为0可不显示）
  day_out_money: string; //日出款限额（为0可不显示）
  week_out_money: string; //周出款限额（为0可不显示）
  moon_out_money: string; //月出款限额（为0可不显示）
  out_money_commission: string; //出款手续费比例（单位%，为0可不显示）
  day_bonus: string; //日礼金（为0可不显示）
  day_bonus_dama: string; //日礼金打码量要求（单位：元，为0可不显示）
  week_bonus: string; //周礼金（为0可不显示）
  week_bonus_dama: string; //周礼金打码量要求（单位：元，为0可不显示）
  moon_bonus: string; //月礼金（为0可不显示）
  moon_bonus_dama: string; //月礼金打码量要求（单位：元，为0可不显示）
  is_user_vip: number; //是否为当前玩家的VIP等级，是为1，否为2
  lottery_gift: Array<ILotteryGift>;
  fanshui:string;
}

export interface ILotteryGift {
  title: string; //奖品名称
  contents: string; //奖品介绍
  pic: string; //奖品图标
}
