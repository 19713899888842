import { IGiftData } from '@/types/config';
import { CommonResponse } from '@/types/response';
import { Request } from '@/utils';
import { testHost } from './host';

export async function getRewardList(params: {
  page: number;
  pagesize: number;
}) {
  try {
    const { data } = await Request.post(
      `${testHost}/api_lottery_list.html`,
      params,
    );
    return data;
  } catch (error) {
    return null;
  }
}

export const luckyDraw = async (params: {
  type_id: number; //1为玩家抽奖，2为代理抽奖
}) => {
  try {
    const { data } = await Request.post(`${testHost}/api_lottery.html`, params);
    return data;
  } catch (error) {
    return null;
  }
};

export const receiveGift = async (code: string) => {
  try {
    const { data } = await Request.post<CommonResponse<Array<IGiftData>>>(
      `${testHost}/api_receive_code.html`,
      {
        code,
      },
    );
    return data;
  } catch (error) {
    return null;
  }
};
