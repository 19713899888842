import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import CommonDrawerV2 from '../common-drawer/CommonDrawerV2';

interface Props {
  birthday: string;
  onChange: (value: string) => void;
}

const BirthdayInput: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useBoolean();
  const [value, setValue] = useState<Dayjs | null>(null);
  const { __ } = useLang();

  useEffect(() => {
    if (props.birthday) {
      setValue(dayjs(props.birthday));
    }
  }, [props.birthday]);

  const handleSubmit = () => {
    props.onChange(value!.format('YYYY-MM-DD'));
    setIsOpen.off();
  };

  return (
    <>
      <OutlinedInput
        value={props.birthday}
        placeholder={__('Select your birthday')}
        inputProps={{ readOnly: true }}
        onClick={setIsOpen.on}
        endAdornment={
          <InputAdornment position="end">
            <ChevronRightRoundedIcon />
          </InputAdornment>
        }
      />

      <CommonDrawerV2
        title={__('Birthday')}
        open={isOpen}
        onOpen={setIsOpen.on}
        onClose={setIsOpen.off}>
        <Box sx={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
          </LocalizationProvider>
          <Stack
            px={2}
            pb={4}
            width="100%"
            spacing={2}
            direction="row"
            alignItems="center">
            <Button fullWidth variant="outlined" onClick={setIsOpen.off}>
              {__('Cancel')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              disabled={value === null}
              onClick={handleSubmit}>
              {__('Sure')}
            </Button>
          </Stack>
        </Box>
      </CommonDrawerV2>
    </>
  );
};

export default BirthdayInput;
