import { atom } from 'recoil';
import { en, pt } from '../locales';
import { LanguageType } from '../types';

const language = localStorage.getItem('__language__') || 'pt';

export const langState = atom<any>({
  key: 'langState',
  default: language === 'pt' ? pt : en,
});

export const languageState = atom<LanguageType>({
  key: 'languageState',
  default: language === 'pt' ? LanguageType.PT : LanguageType.EN,
});
