import IMAGE from '@/assets/footer/ESPORTS.png';
import ICON_BLOCK from '@/assets/icons/icon-block.png';
import ICON_LOCATION from '@/assets/icons/icon-location.png';
import BG from '@/assets/images/ip-lock-bg.png';
import { isIpLockState } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { Box, Stack, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export const IpLock = () => {
  const isIpLock = useRecoilValue(isIpLockState);

  if (isIpLock) {
    return (
      <Box
        sx={{
          maxWidth: globalMaxWidth,
          width: '100%',
          mx: 'auto',
          bgcolor: 'background.default',
          height: '100vh',
          px: 3,
          backgroundImage: `url(${BG})`,
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box
          sx={{
            py: '72px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={IMAGE}
              alt=""
              style={{
                width: 'auto',
                height: '48px',
              }}
            />
          </Box>
          <Typography mt={4.5} variant="subtitle2" fontWeight={500}>
            Your IP: 59.71.240.78
          </Typography>

          <Box mt={4.5}>
            <Stack direction="row" spacing={1.5}>
              <Box sx={{ width: '40px', height: '40px', minWidth: '40px' }}>
                <img src={ICON_BLOCK} alt="" width={40} height={40} />
              </Box>
              <Box sx={{ flex: 'auto', minWidth: 0 }}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {window.location.origin} is not available in your country
                </Typography>
                <Typography variant="body1" mt={1.5}>
                  Due to licensing & regulation laws, we cannot offer our
                  services to you.
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" spacing={2} mt={4.5}>
              <Box sx={{ width: '40px', height: '40px', minWidth: '40px' }}>
                <img src={ICON_LOCATION} alt="" width={40} height={40} />
              </Box>
              <Box sx={{ flex: 'auto', minWidth: 0 }}>
                <Typography variant="subtitle2" fontWeight={700}>
                  Not in a restricted region?
                </Typography>
                <Typography variant="body1" mt={1.5}>
                  Please try again later. If you are using a VPN, try turning it
                  off. Check the status page for information on outages.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  }

  return <Outlet></Outlet>;
};
