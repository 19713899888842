import useResponsive from '@/hooks/useResponsive';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { Box, Grid } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { GameCard } from './GameList';
import HomeSectionContainer from './SectionContainer';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}
// 首页小卡片样式，一行放6个，show_type:1
export const HomeGameListSmall = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return <HomeGameListSmallMobile {...props} />;
  }
  return <HomeGameList2Web {...props} />;
};

export const HomeGameList2Web = (props: Props) => {
  const showList = props.list.slice(0, 10);
  return (
    <HomeSectionContainer
      title={props.title}
      desc={props.desc}
      icon={props?.icon?.square}
      onClickMore={props.onClickMore}
    >
      <Box mb={2}>
        <Grid container gridRow={1} columns={30} spacing={{ xs: 1.5, sm: 2 }}>
          {showList.map((item, index) => {
            return (
              <Grid item key={item.id} md={3}>
                <GameCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </HomeSectionContainer>
  );
};

export const HomeGameListSmallMobile = (props: Props) => {
  const showList = props.list.slice(0, 6);

  return (
    <Box sx={{ mb: '16px' }}>
      <HomeSectionContainer
        title={props.title}
        desc={props.desc}
        // icon={props?.icon?.square}
        onClickMore={props.onClickMore}
      >
        <Box>
          <Grid container columns={24} spacing={{ xs: 1.5, sm: 2 }}>
            {showList.map((item, index) => {
              return (
                <Grid item key={item.id} xs={4}>
                  <GameCard item={item} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </HomeSectionContainer>
    </Box>
  );
};
