import useLang from '@/hooks/useLang';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

interface Props {
  ruleTxt: String;
}

/**
 * 弹窗内容
 *
 * @param {Props} props
 * @return {*}
 */
const CardRuleDrawerConten = (props: Props) => {
  const { __ } = useLang();
  const { language } = useLang();

  const bottomDescription = useMemo(() => {
    if (!props.ruleTxt) {
      return [];
    }
    return props.ruleTxt.split('\\n') || [];
  }, [props.ruleTxt, language]);

  return (
    <Box sx={{ width: '100%', py: 3, px: 2 }} pb={'8px'}>
      {bottomDescription?.length > 0 ? (
        <Box pt={1}>
          {bottomDescription?.map((str, index) => {
            return (
              <Typography key={index} variant="body1" color="text.secondary">
                {str}
              </Typography>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};

export default CardRuleDrawerConten;
