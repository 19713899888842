import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { homeTab as homeTabState } from '@/stores/config';
import { vipState } from '@/stores/vip';
import { IUserInfo } from '@/types';
import { RStatus } from '@/types/vip';
import { IWallet } from '@/types/wallet';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import services from '../services';
import {
  LRModalInitalPage,
  isFetchingUserInfo,
  isLoginState,
  isOpenLRModalState,
  userInfoState,
  walletState,
} from '../stores/auth';

const useAuth = () => {
  const [wallet, setWallet] = useRecoilState(walletState);
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const [user, setUserInfo] = useRecoilState(userInfoState);
  const [vip, setVip] = useRecoilState(vipState);
  const [isFetchingUser, setFetchingUserInfo] =
    useRecoilState(isFetchingUserInfo);
  const navigate = useNavigate();
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const [homeTab, setHomeTab] = useRecoilState(homeTabState);

  // vip默认为0
  const currentVip = useMemo(() => {
    const index = vip.data?.findIndex((item) => item.is_user_vip === 1);
    return index === -1 ? 1 : index + 1;
  }, [vip]);

  const getWallet = async () => {
    try {
      const { data } = await services.auth.getWallet();
      if (data?.wallet) {
        setWallet(data.wallet);
      }
      // if (Array.isArray(data?.wallets)) {
      //   setWallet(data?.wallets[0]);
      // }
    } catch (error) {
      console.log('getWallet failed', error);
    }
  };

  const updateWallet = (data: Partial<IWallet>) => {
    setWallet((prev) => {
      if (prev) {
        return {
          ...prev,
          ...data,
        };
      }
      return null;
    });
  };

  const getUserInfo = async () => {
    try {
      const { data } = await services.auth.getUserInfo();
      if (data?.data) {
        setUserInfo(data.data);
        setIsLogin(true);
      }
    } catch (error: any) {}
  };

  const getVipList = async () => {
    setVip({
      data: vip.data,
      status: RStatus.loading,
    });
    try {
      const data = await services.config.getVipList();
      setVip({
        data,
        status: RStatus.success,
      });
    } catch (error) {
      setVip({
        data: [],
        status: RStatus.error,
      });
    }
  };

  const updateVipList = async () => {
    try {
      const data = await services.config.getVipList();
      setVip({
        data,
        status: RStatus.success,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    localStorage.setItem('access_token', '');
    localStorage.setItem('rememberAccount', '');
    // setHomeTab(HomeTab.Home);
    // setIsLogin(false);
    // setUserInfo(null);
    // setWallet(null);
    // navigate('/');
    window.location.href = '/';
  };

  const login = (data: IUserInfo) => {
    localStorage.setItem('access_token', data.token);
    setIsLogin(true);
    SnackbarUtils.success('Login success');
    setUserInfo(data);
    getWallet();
    navigate('/');
  };

  const openLogin = () => {
    setOpenLRModal(true);
    setInitalPage(0);
  };

  const openRegister = () => {
    setOpenLRModal(true);
    setInitalPage(1);
  };

  return {
    wallet,
    user,
    getWallet,
    updateWallet,
    getUserInfo,
    logout,
    login,
    isLogin,
    isFetchingUser,
    setFetchingUserInfo,
    getVipList,
    updateVipList,
    vip,
    currentVip,
    openLogin,
    openRegister,
  };
};

export default useAuth;
