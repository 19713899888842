import useResponsive from '../../hooks/useResponsive';

interface Props {
  src: string;
  alt: string;
  onClick?: () => void;
}

const IcomImage: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  return (
    <img
      style={{
        width: isMobile ? '12px' : '16px',
        height: isMobile ? '12px' : '16px',
        verticalAlign: 'middle',
        cursor: props.onClick ? 'pointer' : 'default',
      }}
      src={props.src}
      alt="add"
      onClick={props.onClick}
    />
  );
};

export default IcomImage;
