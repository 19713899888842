import vip_lock from '@/assets/vip/vip_lock.png';
import VIP_TAG_4 from '@/assets/vip/vip_tag_4.png';
import VIP_TAG_6 from '@/assets/vip/vip_tag_6.png';
import VIP_TAG_7 from '@/assets/vip/vip_tag_7.png';
import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import { IUserInfo } from '@/types';
// import { IVip } from '@/types/user';
import IMAGE_GAME from '@/assets/images/Type_Game.png';
import { IVipItem } from '@/types/vip';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { VipTag } from './VipTag';

const boxshadowStyle = { boxShadow: 'inset 0 0 32px rgba(255, 255, 255, 0.9)' };
export const VipPrivilegeBox = ({
  user,
  noHeader,
  vipData,
}: {
  user: IUserInfo;
  noHeader?: boolean;
  vipData: IVipItem;
}) => {
  const { __ } = useLang();
  // 没有保级充值金额
  const isNoProtectMoney =
    !vipData.protect_money || +vipData.protect_money <= 0;
  // 没有保级日期倒计时
  const isNoProtectDay = !user.level_end_date || user.level_end_date <= 0;
  // 都没有
  const isNoProtectSection = isNoProtectMoney && isNoProtectDay;
  const isLock = false; // user?.vipState == 2;
  return (
    <Box mt={2} pb={2}>
      <Center height="48px" sx={{ mt: '16px' }}>
        <img
          src={IMAGE_GAME}
          alt=""
          width={30}
          height={30}
          style={{ display: 'none' }}
        />
        <Typography ml={0.5} width="100%" variant="subtitle2" fontWeight={700}>
          {__('Privilege')}
        </Typography>
      </Center>
      <Grid container columns={12} spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={[
              {
                borderRadius: '10px',
                bgcolor: isLock ? 'primary.main' : 'background.neutral',
                p: 2,
              },
              isLock && { ...boxshadowStyle },
            ]}
            position="relative"
          >
            {isLock ? (
              <img
                src={vip_lock}
                alt=""
                style={{
                  top: -2,
                  left: -2,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              />
            ) : null}

            <Stack spacing={1.5}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Typography variant="subtitle2" fontWeight={700}>
                  R$
                  <span style={{ fontSize: 24, marginLeft: 4 }}>
                    {(+(vipData.day_out_money || 0)).toFixed(2)}
                  </span>
                </Typography>
                {isLock ? (
                  <Typography
                    sx={{
                      bgcolor: '#FFC802',
                      ml: '8px',
                      py: '2px',
                      px: '4px',
                      borderRadius: 999,
                    }}
                  >
                    {__('Frozen')}
                  </Typography>
                ) : null}
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {__('每日最大提款')}
              </Typography>
            </Stack>
            <VipTag src={VIP_TAG_7} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={[
              {
                borderRadius: '10px',
                bgcolor: isLock ? 'primary.main' : 'background.neutral',
                p: 2,
              },
              isLock && { ...boxshadowStyle },
            ]}
            position="relative"
          >
            {isLock ? (
              <img
                src={vip_lock}
                alt=""
                style={{
                  top: -2,
                  left: -2,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              />
            ) : null}
            <Stack spacing={1}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Typography variant="h2" fontWeight={700}>
                  {`${vipData?.out_money_commission || '0'}`}
                  <span style={{ fontSize: 16 }}>{`%`}</span>
                </Typography>
                {isLock ? (
                  <Typography
                    sx={{
                      bgcolor: '#FFC802',
                      ml: '8px',
                      py: '2px',
                      px: '4px',
                      borderRadius: 999,
                    }}
                  >
                    {__('Frozen')}
                  </Typography>
                ) : null}
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {__('Withdrawal fee')}
              </Typography>
            </Stack>
            <VipTag src={VIP_TAG_6} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={[
              {
                borderRadius: '10px',
                bgcolor: isLock ? 'primary.main' : 'background.neutral',
                p: 2,
              },
              isLock && { ...boxshadowStyle },
            ]}
            position="relative"
          >
            {isLock ? (
              <img
                src={vip_lock}
                alt=""
                style={{
                  top: -2,
                  left: -2,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              />
            ) : null}
            <Stack spacing={1}>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {`${vipData?.fanshui || ''}`}
                  <span style={{ fontSize: 16 }}>{`%`}</span>
                </Typography>
                {isLock ? (
                  <Typography
                    sx={{
                      bgcolor: '#FFC802',
                      ml: '8px',
                      py: '2px',
                      px: '4px',
                      borderRadius: 999,
                    }}
                  >
                    {__('Frozen')}
                  </Typography>
                ) : null}
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {__('Rebate range')}
              </Typography>
            </Stack>
            <VipTag src={VIP_TAG_4} />
          </Box>
        </Grid>
      </Grid>

      {/* {isNoProtectSection ? null : (
        <>
          <Typography mt={2.5} mb={1.5} variant="subtitle2" fontWeight={700}>
            {__('保级要求')}
          </Typography>
          <Grid container columns={12} spacing={1.5}>
            {isNoProtectMoney ? null : (
              <Grid item xs={12}>
                <Box
                  sx={[
                    {
                      borderRadius: '10px',
                      bgcolor: isLock ? 'primary.main' : 'background.neutral',
                      p: 2,
                    },
                    isLock && { ...boxshadowStyle },
                  ]}
                  position="relative"
                >
                  {isLock ? (
                    <img
                      src={vip_lock}
                      alt=""
                      style={{
                        top: -2,
                        left: -2,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                      }}
                    />
                  ) : null}
                  <Stack spacing={1}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Typography variant="subtitle2" fontWeight={700}>
                        R$
                        <span style={{ fontSize: 24, marginLeft: 4 }}>
                          {(+(vipData.protect_money || 0)).toFixed(2)}
                        </span>
                      </Typography>
                      {isLock ? (
                        <Typography
                          sx={{
                            bgcolor: '#FFC802',
                            ml: '8px',
                            py: '2px',
                            px: '4px',
                            borderRadius: 999,
                          }}
                        >
                          {__('Frozen')}
                        </Typography>
                      ) : null}
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                      {__('保级充值金额')}
                    </Typography>
                  </Stack>
                  <VipTag src={VIP_TAG_4} />
                </Box>
              </Grid>
            )}
            {isNoProtectDay ? null : (
              <Grid item xs={12}>
                <Box
                  sx={[
                    {
                      borderRadius: '10px',
                      bgcolor: isLock ? 'primary.main' : 'background.neutral',
                      p: 2,
                    },
                    isLock && { ...boxshadowStyle },
                  ]}
                  position="relative"
                >
                  {isLock ? (
                    <img
                      src={vip_lock}
                      alt=""
                      style={{
                        top: -2,
                        left: -2,

                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                      }}
                    />
                  ) : null}
                  <Stack spacing={1}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Typography variant="subtitle2" fontWeight={700}>
                        {__('到期').replace(
                          'YYYY-MM-DD',
                          dayjs(user.level_end_date * 1000).format(
                            'YYYY-MM-DD',
                          ),
                        )}
                      </Typography>
                      {isLock ? (
                        <Typography
                          sx={{
                            bgcolor: '#FFC802',
                            ml: '8px',
                            py: '2px',
                            px: '4px',
                            borderRadius: 999,
                          }}
                        >
                          {__('Frozen')}
                        </Typography>
                      ) : null}
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                      {__('保级天数')}
                    </Typography>
                  </Stack>
                  <VipTag src={VIP_TAG_5} />
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      )} */}
    </Box>
  );
};
