import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import services from '@/services';
import { isOpenSetPayPwdDrawerState, userInfoState } from '@/stores/auth';
import { LoadingButton } from '@mui/lab';
import { Box, OutlinedInput, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import CommonDrawerV2 from '../../common-drawer/CommonDrawerV2';
import CodeInput from '../login/CodeInput';
import PasswordInput from '../login/PasswordInput';
import PhoneInput from '../login/PhoneInput';

interface Props {}

const SetPaymentDrawer: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useRecoilState(isOpenSetPayPwdDrawerState);
  const [pwd, setPwd] = useState<string>('');
  const [pwdAgain, setPwdAgain] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { __ } = useLang();
  const user = useRecoilValue(userInfoState);
  const { getUserInfo } = useAuth();

  const handleSendSMS = (isPhone: boolean) => async () => {
    if (isPhone) {
      return services.auth.sendSMS({ type: 4 });
    } else {
      return services.auth.sendEmail({ type: 4 });
    }
  };

  const handleSubmit = (isPhone: boolean) => async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const params = {
      type: isPhone ? 1 : 2,
      password: pwd,
      password1: pwdAgain,
      code,
    };
    const isSuccess = await services.auth.setPaymentPassword(params);
    if (isSuccess) {
      SnackbarUtils.success(`Successfully`);
      setOpen(false);
      getUserInfo();
      clearData();
    }
    setLoading(false);
  };

  const clearData = () => {
    setPwd('');
    setPwdAgain('');
    setCode('');
  };

  if (!user) {
    return <Box></Box>;
  }

  const isBindPhone = !!user.phone;

  return (
    <CommonDrawerV2
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      title="Set Payment Password"
    >
      <Box p="20px" pb={4}>
        <Stack spacing="20px">
          {isBindPhone ? (
            <PhoneInput phone={user.phone} disabled areaCode={55} />
          ) : (
            <OutlinedInput value={user.email} disabled />
          )}
          <CodeInput
            code={code}
            onChange={(v) => setCode(v)}
            onSendSMS={handleSendSMS(isBindPhone)}
          />
          <PasswordInput
            password={pwd}
            onChangePassword={(pwd) => setPwd(pwd)}
            placeholder={__('Enter new password')}
          />
          <PasswordInput
            password={pwdAgain}
            onChangePassword={(pwd) => setPwdAgain(pwd)}
            placeholder={__('Enter new password Again')}
          />
        </Stack>
        <Typography mt="12px" variant="body2" color="text.disabled">
          Only letters, numbers, and common symbols can be used. The minimum
          length is 4 bits and the maximum length is 32 bits
        </Typography>
        <LoadingButton
          sx={{
            background: 'linear-gradient(90deg, #B304FE 0%, #0CC0F7 100%)',
            boxShadow: 'none !important',
            mt: '20px',
          }}
          fullWidth
          variant="contained"
          loading={loading}
          onClick={handleSubmit(isBindPhone)}
        >
          Submit
        </LoadingButton>
      </Box>
    </CommonDrawerV2>
  );
};

export default SetPaymentDrawer;
