import IC_QUESTION from '@/assets/icons/icon_question.png';
import ICON_SIGNOUT from '@/assets/icons/icon_signout.png';
import DEFAULT_AVATAR from '@/assets/images/avatar-default.png';
import CommonSkeleton from '@/components/CommonSkeleton';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { FooterSupportsBar } from '@/layouts/FooterLinks';
import services from '@/services';
import {
  isOpenDepositState,
  isOpenVipRebateState,
  meDrawerState,
  vipListState,
} from '@/stores/auth';
import { homeTab } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import { HomeTab } from '@/types';
import { IVip } from '@/types/user';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Box, Button, Dialog, Stack, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import WithdrawButton from './Withdraw';
import { DailySignView } from './components/DailySignView';
import MeMenus from './components/Menus';
import MenusWeb from './components/MenusWeb';
import { MyWallet } from './components/MyWallet';
import VipRebateDrawer from './components/VipRebateDrawer';

interface Props {
  noHeader?: boolean;
}

const MePage: React.FC<Props> = (props: Props) => {
  const { user, wallet, getWallet } = useAuth();
  const navigate = useNavigate();
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');
  const [open, setOpen] = useBoolean();
  const { logout } = useAuth();
  const theme = useTheme();
  const [vip, setVip] = useState<IVip | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const setOpenVipRebate = useSetRecoilState(isOpenVipRebateState);
  const setVipList = useSetRecoilState(vipListState);
  const openDiposit = useSetRecoilState(isOpenDepositState);
  const setOpenMeDrawer = useSetRecoilState(meDrawerState);
  const homeTabValue = useRecoilValue(homeTab);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickDeposit = () => {
    setOpenMeDrawer(false);
    openDiposit(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await services.config.getVipList();
      if (Array.isArray(data)) {
        const current = data.find((i) => +i.is_user_vip === 1);
        setVip(current || null);
        setVipList(data || []);
      }
      setLoading(false);
    };

    if (homeTabValue === HomeTab.Me) {
      fetchData();
    }
  }, [homeTabValue]);

  const handleSignout = () => {
    logout();
    setOpen.off();
    SnackbarUtils.success(__('Sign out successfully'));
  };

  const _vipRebateRule = () => {
    setOpenVipRebate(true);
  };

  const closePage = () => {
    setOpenMeDrawer(false);
  };

  if (loading || !user) {
    return <CommonSkeleton />;
  }

  return (
    <Box>
      <Box pt={2} pb={3}>
        <Stack direction="row" spacing={3}>
          <MenusWeb active="home" />
          <Box flex="auto" minWidth={0}>
            {/* 账号信息 */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1.5}
                minWidth={0}
              >
                <Box
                  sx={{
                    width: '55px',
                    height: '55px',
                    minWidth: '55px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={user?.avatar || DEFAULT_AVATAR}
                    alt=""
                    style={{ width: 55, height: 55, objectFit: 'cover' }}
                  />
                </Box>
                <Box overflow="hidden" boxSizing="border-box">
                  <Stack flexDirection={'row'} alignItems="center">
                    <Typography
                      onClick={() => (!isMobile ? navigate('/me') : null)}
                      variant="h2"
                      fontWeight={700}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {user?.name || ''}
                    </Typography>
                    <img
                      src={user?.level_pic}
                      alt=""
                      style={{ width: 'auto', height: 16, marginLeft: '4px' }}
                    />
                  </Stack>

                  <CopyToClipboard
                    text={`${user.uid}`}
                    onCopy={() =>
                      enqueueSnackbar(__('Copied successfully'), {
                        variant: 'success',
                      })
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex',
                        mt: '8px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={alpha(theme.palette.info.main, 0.56)}
                      >
                        {__('ID: ')}
                        {user.uid}
                      </Typography>
                      <ContentCopyRoundedIcon
                        sx={{
                          fontSize: '18px',
                          mt: '2px',
                          ml: '6px',
                          color: alpha(theme.palette.info.main, 0.7),
                        }}
                      />
                    </Box>
                  </CopyToClipboard>
                </Box>
              </Stack>
              {/* <Stack direction="row" alignItems="center" spacing={1.5}>
                <SocialButton type="facebook" />
                <SocialButton type="google" />
              </Stack> */}
            </Stack>
            {/* 七天签到 */}
            {/* <DailySignBox /> */}
            <DailySignView />
            {/* 钱包 */}
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: '10px',
                p: '12px 16px',
                mt: 2.5,
              }}
            >
              <Stack>
                <Stack>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      color={alpha(theme.palette.info.main, 0.56)}
                    >
                      {__('My Wallet')}
                    </Typography>
                    <MyWallet />
                  </Stack>
                  <Stack flexDirection={'row'} mt="16px">
                    <Typography
                      variant="body2"
                      color={alpha(theme.palette.info.main, 0.56)}
                    >
                      <span>{__('wallettip1')}</span>
                      <span style={{ color: 'white' }}>{` R$${
                        wallet?.no_dama_money || 0
                      } `}</span>
                      <span>{__('wallettip2')}</span>
                    </Typography>
                    <img
                      src={IC_QUESTION}
                      alt=""
                      style={{ width: 16, height: 16, marginLeft: '4px' }}
                      onClick={_vipRebateRule}
                    />
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: '16px',
                  }}
                >
                  <WithdrawButton type={1} />
                  <Button
                    onClick={handleClickDeposit}
                    fullWidth
                    sx={[
                      {
                        background: ColorfulBg(),
                        height: '40px',
                        borderRadius: '8px',
                      },
                      !isMobile
                        ? { ml: '12px', width: '147px' }
                        : { width: 'auto', flex: 1, ml: '16px' },
                    ]}
                    variant="contained"
                  >
                    {__('充值')}
                  </Button>
                </Stack>
              </Stack>
            </Box>
            {/* TG引导，替换为footer的support列表 */}
            <Box mt={2}>
              <FooterSupportsBar />
            </Box>
            {/* {isMobile && <TGGuide />} */}
            {/* 菜单 */}
            {isMobile && <MeMenus />}
            {/* 退出登录 */}

            <Box
              sx={{
                border: '1px solid #323D4D',
                borderRadius: '10px',
                p: '12px 16px',
                mt: 2.5,
                cursor: 'pointer',
              }}
              onClick={setOpen.on}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'center'}
                spacing={2}
              >
                <img
                  src={ICON_SIGNOUT}
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                <Typography
                  variant="subtitle2"
                  color={alpha(theme.palette.info.main, 0.56)}
                >
                  {__('Sign Out')}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
      <VipRebateDrawer />
      {/* 退出确定弹窗 */}
      <Dialog onClose={setOpen.off} open={open}>
        {/* <CloseButton onClick={setOpen.off} /> */}
        <Box
          py={3}
          px={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle1" fontWeight={800}>
            {__('是否确定退出？')}
          </Typography>
        </Box>

        <Box px={2} pb={2}>
          <Stack width="100%" direction="row" alignItems="center" spacing={1.5}>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              onClick={setOpen.off}
              sx={{
                height: '48px',
                width: '132px',
                color: '#ffffff',
                borderColor: '#323D4D',
              }}
            >
              {__('Cancel')}
            </Button>
            <Button
              fullWidth
              sx={{ background: ColorfulBg(), height: '48px', width: '132px' }}
              variant="contained"
              size="small"
              onClick={handleSignout}
            >
              {__('Sure')}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MePage;
