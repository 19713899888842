// material-ui
import useResponsive from '@/hooks/useResponsive';
import { homeTab, tenantConfigState } from '@/stores/config';
import { HomeTab } from '@/types';
import { Box, Skeleton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import IMAGE_LOGO from '../../assets/topnav/icon_logo_cchwin.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = (props: { onClick?: () => void }) => {
  const navigate = useNavigate();
  const setHomeTab = useSetRecoilState(homeTab);
  const isMobile = useResponsive('down', 'md');
  const location = useLocation();
  const tenantConfig = useRecoilValue(tenantConfigState);

  const handleGoHome = () => {
    if (isMobile && location.pathname === '/') {
      setHomeTab(HomeTab.Home);
    } else {
      navigate('/');
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        pr: 2,
        cursor: 'pointer',
      }}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          handleGoHome();
        }
      }}
    >
      {tenantConfig?.iconConfig?.logoIcon?.navLogo ? (
        <img
          src={tenantConfig?.iconConfig?.logoIcon?.navLogo || IMAGE_LOGO}
          alt="logo"
          style={{
            maxWidth: 125,
            maxHeight: 32,
            width: '100%',
            height: 'auto',
          }}
        />
      ) : (
        <Skeleton
          variant="circular"
          width={32}
          height={32}
          sx={{ mt: '20px' }}
        />
      )}
      {/* <img
        src={tenantConfig?.iconConfig?.logoIcon?.navLogo || IMAGE_LOGO}
        alt="logo"
        style={{ maxWidth: 125, maxHeight: 32, width: '100%', height: 'auto' }}
      /> */}
    </Box>
  );
};

export default LogoSection;
