import ICON_COUPON from '@/assets/icons/icon_coupon.svg';
import ICON_SIGNOUT from '@/assets/icons/icon_signout.png';
import ME_HISTORY from '@/assets/icons/me_history.svg';
import ICON_LIVE from '@/assets/icons/me_live.svg';
import ICON_MESSAGE from '@/assets/icons/me_message.svg';
import ME_SETTINGS from '@/assets/icons/me_settings.svg';
import Center from '@/components/center';
import RedeemDialog from '@/components/coupon/RedeemDialog';
import RedeemFailDrawer from '@/components/coupon/RedeemFailDrawer';
import RedeemResDialog from '@/components/coupon/RedeemResDialog';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { isOpenRedeeDialogState, supportUrlState } from '@/stores/auth';
import { tenantConfigState } from '@/stores/config';
import {
  alpha,
  Box,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

interface Props {
  onClose?: () => void;
}

const UserMenus: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const supportUrl = useRecoilValue(supportUrlState);
  const tenantConfig = useRecoilValue(tenantConfigState);
  const [open, setOpen] = useBoolean();
  const [resOpen, setResOpen] = useBoolean();
  const [failOpen, setFailOpen] = useBoolean();
  const [redeeData, setRedeeData] = useState<any[]>([]);
  const [redeeFailData, setRedeeFailData] = useState<any[]>([]);
  const [isOpenRedeeDialog, setIsOpenRedeeDialog] = useRecoilState(
    isOpenRedeeDialogState,
  );

  const handleClick = (path: string) => {
    props.onClose?.();
    if (path === '/coupon') {
      setOpen.on();
    } else {
      setTimeout(() => {
        navigate(path);
      }, 200);
    }
  };

  const handleSignout = () => {
    logout();
    SnackbarUtils.success(__('Sign out successfully'));
  };

  const onRedeem = (data: any) => {
    setOpen.off();
    setIsOpenRedeeDialog(false);
    console.log('_data', data);
    if (Array.isArray(data) && data?.length > 0) {
      console.log('--data', data);
      setRedeeData(data);
      setResOpen.on();
    } else {
      console.log('++data', data);
      setRedeeFailData(data?.ruleDetails || []);
      setFailOpen.on();
    }
  };

  return (
    <Box>
      {/* <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/reward')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img style={{ width: 24, height: 24 }} alt="" src={ICON_REWARD} />
          <Typography variant="body1" fontWeight={500}>
            {'reward'}
          </Typography>
        </Stack>
      </MenuItem>
      <Divider sx={{ m: '0 !important' }} /> */}
      <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/records')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img
            style={{ width: 24, height: 24 }}
            alt=""
            src={tenantConfig?.iconConfig?.personalIcon?.history || ME_HISTORY}
          />
          <Typography variant="body1" fontWeight={500}>
            {__('History')}
          </Typography>
        </Stack>
      </MenuItem>
      <Divider sx={{ m: '0 !important' }} />
      <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/message')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img
            style={{ width: 24, height: 24 }}
            alt=""
            src={
              tenantConfig?.iconConfig?.personalIcon?.message || ICON_MESSAGE
            }
          />
          <Typography variant="body1" fontWeight={500}>
            {'Message'}
          </Typography>
        </Stack>
      </MenuItem>
      {/* <Divider sx={{ m: '0 !important' }} /> */}
      {/* <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/records')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img style={{ width: 24, height: 24 }} alt="" src={ICON_EXCHANGE} />
          <Typography variant="body1" fontWeight={500}>
            {'Exchange'}
          </Typography>
        </Stack>
      </MenuItem> */}
      {/* <Divider sx={{ m: '0 !important' }} />
      <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/account')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img style={{ width: 24, height: 24 }} alt="" src={ME_USER} />
          <Typography variant="body1" fontWeight={500}>
            {__('Account')}
          </Typography>
        </Stack>
      </MenuItem> */}
      <Divider sx={{ m: '0 !important' }} />
      <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/coupon')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img
            style={{ width: 24, height: 24 }}
            alt=""
            src={tenantConfig?.iconConfig?.personalIcon?.coupon || ICON_COUPON}
          />
          <Typography variant="body1" fontWeight={500}>
            {__('Coupon')}
          </Typography>
        </Stack>
      </MenuItem>
      <Divider sx={{ m: '0 !important' }} />
      <MenuItem sx={{ height: '48px' }} onClick={() => handleClick('/setting')}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <img
            style={{ width: 24, height: 24 }}
            alt=""
            src={tenantConfig?.iconConfig?.personalIcon?.setting || ME_SETTINGS}
          />
          <Typography variant="body1" fontWeight={500}>
            {__('Setting')}
          </Typography>
        </Stack>
      </MenuItem>
      <Divider sx={{ m: '0 !important' }} />
      <MenuItem
        sx={{ height: '48px' }}
        onClick={() => {
          if (supportUrl) {
            window.location.href = supportUrl;
          }
        }}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <img
            style={{ width: 24, height: 24 }}
            alt=""
            src={tenantConfig?.iconConfig?.personalIcon?.support || ICON_LIVE}
          />
          <Typography variant="body1" fontWeight={500}>
            {__('Live Support')}
          </Typography>
        </Stack>
      </MenuItem>
      <Divider sx={{ m: '0 !important' }} />

      <Center
        sx={{
          height: '48px',
        }}
        onClick={handleSignout}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <img src={ICON_SIGNOUT} alt="" style={{ width: 20, height: 20 }} />
          <Typography
            variant="subtitle2"
            color={alpha(theme.palette.info.main, 0.56)}
          >
            {__('Sign Out')}
          </Typography>
        </Stack>
      </Center>

      <RedeemDialog
        open={open || isOpenRedeeDialog}
        onClose={() => {
          setOpen.off();
          setIsOpenRedeeDialog(false);
        }}
        onRedee={onRedeem}
      />
      <RedeemResDialog
        resData={redeeData}
        open={resOpen}
        onClose={setResOpen.off}
      />
      <RedeemFailDrawer
        open={failOpen}
        onClose={setFailOpen.off}
        list={redeeFailData || []}
      />
    </Box>
  );
};

export default UserMenus;
