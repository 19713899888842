import { PageHeader } from '@/components/BackHeader';
import CommonSkeleton from '@/components/CommonSkeleton';
import Center from '@/components/center';
import DepositAmounts, {
  FirstDepostExtraMoney,
} from '@/components/deposit/DepositAmounts';
import DepositPaymentMethods from '@/components/deposit/DepositPaymentMethods';
import DepositDialogHeader from '@/components/deposit/components/Heder';
import { findRuleByValue } from '@/components/deposit/utils';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import { useDepositCoupons } from '@/hooks/useDepositCoupons';
import {
  FirstDepositDto,
  useFirstDepositBouns,
} from '@/hooks/useFirstDepositBouns';
import useLang from '@/hooks/useLang';
import { useWeekCardBonus } from '@/hooks/useWeekCardBonus';
import services from '@/services';
import { depositTabState } from '@/stores/auth';
import {
  depositActivityState,
  payChannelDataState,
  weekCardActivityState,
} from '@/stores/config';
import { ColorfulBg } from '@/styles';
import { globalMaxWidth } from '@/theme';
import { customColor } from '@/theme/customColor';
import { ActivityType, LanguageType } from '@/types';
import { IDeposit, IDepositCoupon } from '@/types/config';
import { hideScrollbarX, hideScrollbarY } from '@/utils/cssStyles';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Coupon } from './Coupon';
import { DepositActivitySelect } from './components';
import { DepositTabs } from './components/DepositTabs';
import { WeekCardList } from './components/WeekCardList';

interface Props {
  type?: 'page' | 'dialog';
  height?: string;
  onClose?: () => void;
}

const MAX_DEPOSIT_AMOUNT = 20000;

const DepositPage: React.FC<Props> = (props: Props) => {
  const isDialog = props.type === 'dialog';
  const [aDetails, setADetails] = useRecoilState(depositActivityState);
  const [aweekCards, setAWeekCards] = useRecoilState(weekCardActivityState);

  const navigate = useNavigate();
  const { __, language } = useLang();
  const [loading, setLoading] = useState<boolean>(true);
  const [depositList, setDepositList] = useState<IDeposit[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<IDeposit | null>(null);
  const [money, setMoney] = useState<number>(0);
  const [depositType, setDepositType] = useState<number>(0); //0充值，1周卡
  // const [checkFirstDeposit, setCheckFirstDeposit] = useBoolean();
  // const [hasFirstDeposit, setHasFirstDeposit] = useBoolean(false);
  const depositTab = useRecoilValue(depositTabState);

  const [inputMoney, setInputMoney] = useState<string>('');
  const [isOpenDepositUrl, setIsOpenDepositUrl] = useBoolean();
  const [depositUrl, setDepositUrl] = useState<string>('');
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const { coupons } = useDepositCoupons();
  const [selectedCoupon, setSelectedCoupon] = useState<IDepositCoupon>();
  const { getWallet, isLogin, openLogin } = useAuth();
  const [payChannelData, setPayChannelData] =
    useRecoilState(payChannelDataState);

  const [selectedActivity, setSelectedActivity] = useState<FirstDepositDto>();
  const { details: depositActivitys, loading: firstDepositLoading } =
    useFirstDepositBouns(setSelectedActivity);
  const { weekCards, loading: weekCardLoading } = useWeekCardBonus();

  useEffect(() => {
    const fetchData = async () => {
      const data = payChannelData; // await services.config.getDeopositMethods();
      if (data?.onlinepay_list) {
        const list = Object.keys(data.onlinepay_list).map(
          (key) => data.onlinepay_list[key],
        );
        setDepositList(list);
        if (list[0]) {
          setSelectedDeposit(list[0]);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [payChannelData, isLogin]);

  const firstDepositRule = useMemo(() => {
    if (!selectedActivity || !inputMoney) {
      return null;
    }
    const rule = findRuleByValue(+inputMoney, selectedActivity.rules);
    return rule;
  }, [selectedActivity, inputMoney]);

  const bottomDescription = useMemo(() => {
    if (!selectedActivity) {
      return [];
    }

    const isEn = language === LanguageType.EN;

    return (
      (isEn
        ? selectedActivity?.blurb?.enUS.split('\\n')
        : selectedActivity?.blurb?.ptBR.split('\\n')) || []
    );
  }, [selectedActivity, language]);

  // 是否可以
  const isAvaliable = !!selectedDeposit && (money > 0 || +inputMoney > 0);

  const getDepositParams = (deposit: IDeposit, coupon?: IDepositCoupon) => {
    let params: any = {
      pay_id: deposit.id,
    };

    if (money === 0) {
      const { min_money, max_money } = deposit!;
      if (+inputMoney > max_money || +inputMoney < min_money) {
        SnackbarUtils.error(
          __('moneyLimit')
            .replace('{min}', min_money)
            .replace('{max}', max_money),
        );
        return null;
      }
      params['money'] = inputMoney;
      // 首充活动
      if (firstDepositRule) {
        params['activityId'] = firstDepositRule.activityId;
      }
    } else {
      params['money'] = `${money}`;
      // 首充活动
      if (selectedActivity) {
        const rule = findRuleByValue(+money, selectedActivity.rules);
        if (rule) {
          params['activityId'] = rule.activityId;
        }
      }
    }

    if (coupon) {
      params['card_id'] = coupon.id;
    }
    return params;
  };

  const handleDeposit = async () => {
    if (!isLogin) {
      openLogin();
      return;
    }
    if (depositLoading) {
      return;
    }
    if (!selectedDeposit) {
      return;
    }
    const params = getDepositParams(selectedDeposit, selectedCoupon);
    if (!params) {
      return;
    }

    setDepositLoading(true);
    try {
      const { data } = await services.wallet.deposit(params);
      const { type = '', info = '' } = data;
      if (type === 'url' && info) {
        setDepositType(0);
        setIsOpenDepositUrl.on();
        setDepositUrl(info);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDepositLoading(false);
    }
  };

  const handleSelectDepositType = (item: IDeposit) => {
    setSelectedDeposit(item);
    setMoney(0);
    setInputMoney('');
  };

  const handleChangeInputMoney: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const value = e.target.value;
    const moneyT = value.replace(/[^\d]/g, '');
    setInputMoney(moneyT);
    setMoney(0);
  };

  // inputMoney 限制最多2位小数
  // 限制最大输入 20000
  const handleAmountBlur = () => {
    if (+inputMoney > MAX_DEPOSIT_AMOUNT) {
      setInputMoney(MAX_DEPOSIT_AMOUNT.toString());
      return;
    }
    const moneyT = inputMoney?.replace(/[^\d]/g, '');
    const value = +moneyT;
    if (value > 0) {
      setInputMoney(moneyT);
    }

    // if (inputMoney.includes('.') && inputMoney.split('.')[1]?.length > 2) {
    //   const value = +inputMoney;
    //   if (value > 0) {
    //     setInputMoney(value.toFixed(2));
    //   }
    // }
  };

  const handleChangeMoney = (v: number) => {
    setMoney(v);
    setInputMoney('');
  };

  const closeDepositDialog = async () => {
    getWallet();
    setIsOpenDepositUrl.off();
    const res = await services.config.getActivityList(
      depositType === 1 ? ActivityType.周卡 : ActivityType.单次充值活动,
    );
    if (depositType === 1) {
      setAWeekCards(res || []);
      return;
    }

    const activityId = res?.list?.[0]?.id;

    if (!activityId) {
      setLoading(false);
      return;
    }

    try {
      const list = res?.list?.map((item: FirstDepositDto) => {
        return {
          ...item,
          rules: item?.rules?.map((rule, idx) => {
            return {
              ...rule,
              nextRuleValue: item.rules?.[idx + 1]?.rules?.[0]?.value,
            };
          }),
        };
      });
      setSelectedActivity(list[0] || {});
      setADetails(list);
    } catch (error) {
      setADetails([]);
    }
  };

  const weekCarBuyCb = (info: any) => {
    setDepositType(1);
    setIsOpenDepositUrl.on();
    setDepositUrl(info);
  };

  if (loading || (firstDepositLoading && depositActivitys.length === 0)) {
    return (
      <Box minHeight={isDialog ? props.height : '100vh'} px={2}>
        {isDialog ? null : (
          <Center height={60}>
            <PageHeader onBack={() => navigate('/')} title={__('Deposit')} />
          </Center>
        )}
        <CommonSkeleton />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          zIndex: 100,
          top: 0,
          left: 0,
          pl: 0,
          pr: 0,
          bgcolor: 'background.paper',
        }}
      >
        <DepositDialogHeader
          onClose={props.onClose}
          type={selectedActivity?.type}
        />
        <DepositTabs />
      </Box>

      {depositTab === 'amount' ? (
        <Box
          minHeight={isDialog ? props.height : '100vh'}
          maxHeight={isDialog ? props.height : '100vh'}
          sx={{
            ...hideScrollbarY,
          }}
          display="flex"
          flexDirection="column"
        >
          {isDialog ? null : (
            <Center height={60}>
              <PageHeader onBack={() => navigate('/')} title={__('Deposit')} />
            </Center>
          )}

          <Box px={2} flex="auto">
            <DepositAmounts
              deposit={selectedDeposit}
              money={money}
              onChangeMoney={handleChangeMoney}
              selectedCoupon={selectedCoupon}
              firstDeposit={selectedActivity}
              // onSelectFirstDeposit={(id) => {
              //   selectedActivityIdRef.current = id;
              // }}
            />
            <Box pt={1}>
              <form>
                <OutlinedInput
                  value={inputMoney}
                  startAdornment={
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 16, fontWeight: 700 }}
                    >
                      R$
                    </Typography>
                  }
                  name="deposit-input-name"
                  onChange={handleChangeInputMoney}
                  fullWidth
                  type="number"
                  placeholder={__('Enter your amount')
                    .replace('{min}', selectedDeposit?.min_money)
                    .replace('{max}', selectedDeposit?.max_money)}
                  onBlur={handleAmountBlur}
                  autoComplete="false"
                  inputProps={{
                    sx: {
                      fontSize: 24,
                      '&::placeholder': {
                        fontSize: 14,
                        fontWeight: 400,
                        transform: 'translateY(-2px)',
                      },
                      py: '11px !important',
                      pl: '16px !important',
                    },
                  }}
                  sx={{ px: 2, bgcolor: 'background.neutral' }}
                  endAdornment={
                    <InputAdornment position="end">
                      {/* <ExtraMoney money={+inputMoney || 0} coupon={selectedCoupon} /> */}
                      <FirstDepostExtraMoney
                        money={firstDepositRule?.rulePrizeValue ?? ''}
                      />
                    </InputAdornment>
                  }
                />
              </form>
            </Box>
            {/* {hasFirstDeposit && (
            <Box pt={1.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  checked={checkFirstDeposit}
                  checkedIcon={<CheckCircleIcon />}
                  size="small"
                  icon={<RadioButtonUncheckedIcon />}
                  onChange={setCheckFirstDeposit.toggle}
                  sx={{
                    p: 0,
                  }}
                ></Checkbox>
                <Typography
                  variant="body2"
                  color="text.primary"
                  onClick={setCheckFirstDeposit.toggle}
                  sx={{ cursor: 'pointer' }}
                >
                  {__('首次充值（只有一次机会）')}
                </Typography>
              </Stack>
            </Box>
          )} */}
            <Box
              sx={{
                // bgcolor: 'background.default',
                background: 'linear-gradient(180deg, #1C2026 0%, #232B36 100%)',
                borderRadius: 1,
                mt: 1.5,
                pt: 2,
              }}
            >
              <Stack px={2} spacing={1}>
                <Typography variant="body2" lineHeight={1} fontWeight={700}>
                  {__('Select A Channel')}
                </Typography>
                <DepositPaymentMethods
                  depositList={depositList}
                  selectedDeposit={selectedDeposit}
                  onSelect={handleSelectDepositType}
                />
              </Stack>
              <DepositActivitySelect
                selected={selectedActivity}
                list={depositActivitys}
                onSelect={(item) => {
                  if (item?.userActivityState === 3) {
                    SnackbarUtils.warning(__('same tip'), void 0, 2500);
                  }
                  setSelectedActivity(item);
                }}
              />
            </Box>

            {coupons.length > 0 ? (
              <>
                <Typography
                  mb={1.5}
                  mt={2.5}
                  variant="subtitle2"
                  fontWeight={700}
                >
                  {__('Select Coupon (n)').replace('{n}', `${coupons.length}`)}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1.5}
                  sx={{
                    ...hideScrollbarX,
                  }}
                >
                  {coupons.map((item, index) => {
                    return (
                      <Coupon
                        key={index}
                        data={item}
                        isSelect={item.id === selectedCoupon?.id}
                        onSelect={() => {
                          if (selectedCoupon?.id === item.id) {
                            setSelectedCoupon(void 0);
                            return;
                          } else {
                            setSelectedCoupon(item);
                          }
                        }}
                      />
                    );
                  })}
                </Stack>
              </>
            ) : null}
            <Box pb={3}>
              <LoadingButton
                fullWidth
                disabled={!isAvaliable}
                variant="contained"
                onClick={handleDeposit}
                loading={depositLoading}
                sx={{
                  // background: isAvaliable ? ColorfulBg : '#f00',
                  borderRadius: '8px',
                }}
                style={{
                  backgroundColor: isAvaliable
                    ? ColorfulBg()
                    : customColor.disable_button_bg,
                }}
              >
                {__('Deposit')}
              </LoadingButton>
              {bottomDescription?.length > 0 ? (
                <Box pt={1}>
                  {bottomDescription?.map((str, index) => {
                    return (
                      <Typography
                        key={index}
                        variant="body2"
                        color="text.secondary"
                        lineHeight={'15px'}
                      >
                        {str}
                      </Typography>
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          </Box>

          <Dialog
            PaperProps={{
              sx: {
                width: '100%',
                maxWidth: globalMaxWidth,
                // left: {
                //   xs: 0,
                //   sm: `calc(50% - ${globalMaxWidth / 2}px)`,
                // },
              },
            }}
            open={isOpenDepositUrl}
            onClose={() => {
              closeDepositDialog();
            }}
          >
            <Box py={2}>
              <iframe
                title="deposit"
                src={depositUrl}
                frameBorder={0}
                id="gameIframe"
                style={{
                  width: '100%',
                  height: '80vh',
                  verticalAlign: 'middle',
                }}
              ></iframe>
            </Box>
          </Dialog>
        </Box>
      ) : (
        <Box
          minHeight={isDialog ? props.height : '100vh'}
          maxHeight={isDialog ? props.height : '100vh'}
          sx={{
            ...hideScrollbarY,
          }}
          display="flex"
          flexDirection="column"
        >
          <WeekCardList
            list={weekCards}
            deposit={selectedDeposit}
            onBuyCb={weekCarBuyCb}
            loading={loading}
          />
        </Box>
      )}
    </>
  );
};

export default DepositPage;
