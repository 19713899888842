import ICON_LEFT from '@/assets/buttons/left-button.png';
import ICON_CLOSE from '@/assets/icons/ic_black_close.png';
import { useAuthJump } from '@/hooks/useAuthJump';
import useResponsive from '@/hooks/useResponsive';
import { diaLogBannerList, isShowDaLogBanner } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { IBannerItem } from '@/types/config';
import { Box, Dialog, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Media5Dialog = () => {
  const media5 = useRecoilValue(diaLogBannerList);
  const isMobile = useResponsive('down', 'md');
  const swiperRef = useRef<any>();
  const { authJump } = useAuthJump();
  const [isShow, setIsShowDaLogBanner] = useRecoilState(isShowDaLogBanner);
  const [open, setOpen] = useState<boolean>(media5.length > 0 && !isShow);

  const width = Math.min(window.innerWidth, globalMaxWidth);

  const onCloseDialog = () => {
    setOpen(false);
    setIsShowDaLogBanner(true);
  };

  const onLeft = () => {
    swiperRef?.current?.slidePrev?.();
  };

  const onRight = () => {
    swiperRef?.current?.slideNext?.();
  };

  const handleClick = (data: IBannerItem) => {
    const success = authJump(data);
    if (!success) {
      return;
    }
    onCloseDialog();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: width - 32,
          borderRadius: '20px 20px 0 0',
          position: 'relative',
          overflowY: 'visible',
        },
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
      }}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="sign-in"
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: width - 32,
          overflow: 'hidden',
          borderRadius: isMobile ? '8px' : '0',
        }}
      >
        <Swiper
          centeredSlides={true}
          slidesPerView={1}
          className="home-dailog-banner-swiper"
          spaceBetween={16}
          pagination={true}
          modules={[Pagination, Autoplay]}
          loop
          onSwiper={(s) => {
            swiperRef.current = s;
          }}
          autoplay={{
            delay: 2500,
            reverseDirection: false,
            disableOnInteraction: false,
          }}
        >
          {media5?.map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img
                  style={{
                    objectFit: 'fill',
                  }}
                  src={item?.pic}
                  alt={item?.title}
                  onClick={() => handleClick(item)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Stack
        sx={{
          position: 'absolute',
          bottom: -52,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '28px',
          height: '28px',
          borderRadius: '14px',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: alpha('#000000', 0.4),
          zIndex: 100,
          cursor: 'pointer',
        }}
        onClick={() => {
          onCloseDialog();
        }}
      >
        <img
          src={ICON_CLOSE}
          alt=""
          style={{ width: '28px', height: '28px', objectFit: 'cover' }}
        />
      </Stack>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 4,
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 100,
        }}
        onClick={onLeft}
      >
        <img
          src={ICON_LEFT}
          alt=""
          style={{ width: '28px', height: '28px', objectFit: 'cover' }}
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 4,
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 100,
        }}
        onClick={onRight}
      >
        <img
          src={ICON_LEFT}
          alt=""
          style={{
            width: '28px',
            height: '28px',
            objectFit: 'cover',
            transform: 'rotate(180deg)',
          }}
        />
      </Box>
    </Dialog>
  );
};
