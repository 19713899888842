import useLang from '@/hooks/useLang';
import AuthTabs from '@/pages/Auth/components/AuthTabs';
import { isOpenRegisterModalState } from '@/stores/auth';
import { SigninType } from '@/types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import RegisterForm from '../register/RegisterForm';

const SignupModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(isOpenRegisterModalState);
  const { __ } = useLang();

  const HeaderView = (
    <Box
      position="relative"
      height={56}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h3" textAlign="center">
        {__('Sign Up')}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton color="info" onClick={() => setIsOpen(false)}>
          <CloseIcon sx={{ fontSize: 24, color: '#8B899C' }} />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
        },
      }}
      onClose={() => setIsOpen(false)}
      aria-labelledby="sign-in"
    >
      <Box sx={{ flex: 1 }} bgcolor="background.neutral">
        {HeaderView}
        <Divider sx={{ mb: 1 }} />
        <Box px={2} pb={0.5}>
          <AuthTabs
            emailTab={
              <RegisterForm type={SigninType.Email} displayType="dialog" />
            }
            phoneTab={
              <RegisterForm type={SigninType.Phone} displayType="dialog" />
            }
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default SignupModal;
