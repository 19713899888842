import CommonSkeleton from '@/components/CommonSkeleton';
import EmptyData from '@/components/EmptyData';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import { RStatus } from '@/types/vip';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import 'swiper/css';
import RewardItem from './RewardItem';

interface Props {}

const VipButton = (props: {
  vip: number;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      sx={{
        width: '72px',
        minWidth: '72px',
        borderRadius: 999,
        height: '32px',
        borderColor: props.active ? 'primary.main' : '#3C3952',
        color: props.active ? 'primary.main' : '#3C3952',
      }}
      size="small"
      variant="outlined"
      onClick={props.onClick}
    >
      VIP {props.vip}
    </Button>
  );
};

// const VIP_LIST = [1, 2, 3, 4, 5, 6, 7];

const VipRewards: React.FC<Props> = (props: Props) => {
  const { vip, currentVip } = useAuth();
  let initVip = currentVip - 1;
  const { __ } = useLang();
  const [active, setActive] = useState(initVip);
  // let rws = vip.data.length > 0 ? vip.data[0] : [];
  const swiperRef = useRef<any>();

  if (vip.status !== RStatus.success) {
    return (
      <Box>
        <CommonSkeleton />
      </Box>
    );
  }

  return (
    <Box mt={3}>
      {/* <Box sx={{ height: '32px', mt: 4, mb: 2, px: 2, ...hideScrollbarX }}>
        <Stack direction="row" spacing={1}>
          {vip.data?.map((vip, index) => {
            return (
              <VipButton
                key={index}
                vip={index + 1}
                active={active === index}
                onClick={() => {
                  setActive(index);
                  swiperRef?.current?.slideTo(index);
                }}
              />
            );
          })}
          <Box sx={{ width: '8px', height: '8px', minWidth: '8px' }}></Box>
        </Stack>
      </Box> */}
      <Box mb={2} px={2}>
        <Typography variant="subtitle2">{__('Rewards')}</Typography>
      </Box>
      <Stack px={2} pb={2}>
        {vip?.data ? (
          vip?.data?.map((vip, index) => {
            if (index > 0) {
              return null;
            }
            return vip.lottery_gift?.length > 0 ? (
              <Grid container columns={12} spacing={1.5} key={index}>
                {vip.lottery_gift?.map((gift, idx) => {
                  return (
                    <Grid item xs={6} key={idx}>
                      <RewardItem key={idx} data={gift} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <EmptyData />
            );
          })
        ) : (
          <EmptyData />
        )}
      </Stack>
      {/* <Swiper
        initialSlide={initVip}
        centeredSlides={true}
        // spaceBetween={16}
        className="mySwiper"
        noSwiping
        allowTouchMove={false}
        onSwiper={(s) => {
          swiperRef.current = s;
        }}
      >
        {vip.data?.map((vip, index) => {
          return (
            <SwiperSlide key={index}>
              {vip.lottery_gift?.length > 0 ? (
                <Grid container columns={24} spacing={1.5}>
                  {vip.lottery_gift?.map((gift, idx) => {
                    return (
                      <Grid item xs={12}>
                        <RewardItem key={idx} data={gift} />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <EmptyData />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper> */}
    </Box>
  );
};

export default VipRewards;
