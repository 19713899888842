import { IVipItem, RStatus } from '@/types/vip';
import { atom } from 'recoil';

export const vipState = atom<{
  data: IVipItem[];
  status: RStatus;
}>({
  key: 'vipState',
  default: {
    data: [],
    status: RStatus.idle,
  },
});
