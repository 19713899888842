import { useEffect, useState } from "react";
import services from "../services";
import { IMyBox } from "../types/box";

const useMyBox = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [myBox, setMyBox] = useState<IMyBox | null>(null);

  useEffect(() => {
    const fetchOwnBox = async () => {
      try {
        setLoading(true);
        const { data } = await services.config.getOwnBoxes();
        setMyBox(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchOwnBox();
  }, []);
  return {
    loading,
    myBox,
  };
};

export default useMyBox;
