import IMAGE_COIN from '@/assets/icons/coin.png';
import { InviteRecordDto } from '@/hooks/useInviteRecords';
import useLang from '@/hooks/useLang';
import { InviteEventsInfoDto } from '@/types';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

export enum InviteStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
}

interface Props {
  data: InviteRecordDto;
  info: InviteEventsInfoDto | null;
}

export const RecordCard = (props: Props) => {
  const { __ } = useLang();
  const status =
    props.data.inviteeState === 1
      ? InviteStatus.Completed
      : InviteStatus.InProgress;

  return (
    <Box
      sx={{
        bgcolor: '#313D4D',
        borderRadius: 1,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: '40px',
          px: 2,
        }}
      >
        <InviteStatusTitle status={status} />
        <Typography variant="body2" color="text.secondary">
          {dayjs(props.data.regTime * 1000).format('YYYY-MM-DD HH:mm')}
        </Typography>
      </Stack>
      <Divider
        sx={{
          borderColor: 'rgba(255, 255, 255, 0.15)',
        }}
      />

      <Box sx={{ p: 2 }}>
        <Typography mb={1.5} variant="h2" fontWeight={700}>
          {props.data.userId}
        </Typography>
        <Stack direction="row" alignItems="center">
          <Box sx={{ flex: 1, mr: 2 }}>
            <Typography variant="body2" color="text.secondary" mb={0.5}>
              {__('Accumulated recharge')}
            </Typography>
            {status === InviteStatus.Completed ||
            +props.data.rechargeAmt >= +(props.info?.vaildInviteeAmt ?? 0) ? (
              <Box color="success.main">
                <CheckCircleIcon sx={{ fontSize: '18px', mr: '2px' }} />
              </Box>
            ) : (
              <Stack direction="row" alignItems="center">
                <img
                  src={IMAGE_COIN}
                  alt="coin"
                  style={{ width: '16px', height: '16px' }}
                />
                <Typography variant="subtitle1" ml={0.5}>
                  <span style={{ color: '#FFC802' }}>
                    {props.data.rechargeAmt}
                  </span>
                  /{props.info?.vaildInviteeAmt ?? 0}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary" mb={0.5}>
              {__('Cumulative bets')}
            </Typography>
            {status === InviteStatus.Completed ||
            +props.data.orderedAmt >=
              +(props.info?.vaildInviteeOrderAmt ?? 0) ? (
              <Box color="success.main">
                <CheckCircleIcon sx={{ fontSize: '18px', mr: '2px' }} />
              </Box>
            ) : (
              <Stack direction="row" alignItems="center">
                <img
                  src={IMAGE_COIN}
                  alt="coin"
                  style={{ width: '16px', height: '16px' }}
                />
                <Typography variant="subtitle1" ml={0.5}>
                  <span style={{ color: '#FFC802' }}>
                    {props.data.orderedAmt}
                  </span>
                  /{props.info?.vaildInviteeOrderAmt ?? 0}
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const InviteStatusTitle = ({ status }: { status: InviteStatus }) => {
  const { __ } = useLang();
  if (status === InviteStatus.InProgress) {
    return (
      <Stack direction="row" alignItems="center" color="#FFC802">
        <AccessTimeFilledIcon sx={{ fontSize: '18px', mr: '2px' }} />
        <Typography variant="body1" fontWeight={500}>
          {__('In Progress')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" color="success.main">
      <CheckCircleIcon sx={{ fontSize: '18px', mr: '2px' }} />
      <Typography variant="body1" fontWeight={500}>
        {__('Compeleted')}
      </Typography>
    </Stack>
  );
};
