import { colorConfig } from '@/styles';
import { Box } from '@mui/material';

export const DepositBouns = (props: { bouns?: string }) => {
  if (!props.bouns) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        px: '4px',
        py: '2px',
        bgcolor: colorConfig().secondary,
        borderRadius: '0 8px',
        fontSize: '12px',
        lineHeight: 1,
      }}
    >
      {props.bouns}
    </Box>
  );
};
