import { plateformState } from '@/stores/config';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

const usePlatform = () => {
  const platformValue = useRecoilValue(plateformState);

  const list = useMemo(() => {
    return Object.keys(platformValue).map((key) => platformValue[+key]);
  }, [platformValue]);

  const getPlatformTitle = (id?: number) => {
    if (!id) {
      return '-';
    }
    const item = platformValue.find((pl) => {
      return pl.id == id;
    });
    return item?.title || id || '-';
  };

  const getPlatform = (id: number) => {
    return platformValue?.[id] || null;
  };

  return {
    getPlatformTitle,
    platformList: list,
    getPlatform,
  };
};

export default usePlatform;
