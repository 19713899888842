// theme
import palette from '../../theme/palette';
//
import { ThemeColorPresetsValue } from './types';

const themePalette = palette('dark');

export const presets = [
  // DEFAULT
  {
    name: 'default',
    ...themePalette.primary,
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#CCF4FE',
    light: '#68CDF9',
    main: '#078DEE',
    dark: '#0351AB',
    darker: '#012972',
    contrastText: '#ECEDEE',
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#ECEDEE',
  },
  // BLUE
  {
    name: 'blue',
    lighter: 'rgba(0, 145, 255, .15)',
    light: 'rgba(0, 145, 255, .35)',
    main: 'rgba(0, 145, 255, .65)',
    dark: 'rgba(0, 145, 255, .85)',
    darker: '#0091FF',
    contrastText: '#ECEDEE',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: 'rgba(247, 181, 0, .15)',
    light: 'rgba(247, 181, 0, .35)',
    main: 'rgba(247, 181, 0, .65)',
    dark: 'rgba(247, 181, 0, .85)',
    darker: '#F7B500',
    contrastText: '#ECEDEE',
  },
  // RED
  {
    name: 'red',
    lighter: 'rgba(224, 32, 32, .15)',
    light: 'rgba(224, 32, 32, .35)',
    main: 'rgba(224, 32, 32, .65)',
    dark: 'rgba(224, 32, 32, .85)',
    darker: '#E02020',
    contrastText: '#ECEDEE',
  },
  // GREEN
  {
    name: 'green',
    lighter: 'rgba(68, 215, 182, .15)',
    light: 'rgba(68, 215, 182, .35)',
    main: 'rgba(68, 215, 182, .65)',
    dark: 'rgba(68, 215, 182, .85)',
    darker: '#44D7B6',
    contrastText: '#ECEDEE',
  },
];

export const defaultPreset = presets[0];
export const cyanPreset = presets[1];
export const purplePreset = presets[2];
export const bluePreset = presets[3];
export const orangePreset = presets[4];
export const redPreset = presets[5];
export const greenPreset = presets[6];

export const presetsOption = presets.map((color) => ({
  name: color.name,
  value: color.main,
}));

export function getPresets(key: ThemeColorPresetsValue) {
  return {
    default: defaultPreset,
    cyan: cyanPreset,
    purple: purplePreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    green: greenPreset,
  }[key];
}
