import ICON_CLOSE from '@/assets/icons/ic_close_snp.png';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { showMoneyDialogState } from '@/stores/auth';
import { langState } from '@/stores/lang';
import { ColorfulBg } from '@/styles';
import { LanguageType } from '@/types';
import { hideScrollbarY } from '@/utils/cssStyles';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';

const MoneyDialog: React.FC = () => {
  const { __ } = useLang();
  const [lang, setLang] = useRecoilState(langState);
  const [showMoneyDialog, setShowMoneyDialog] =
    useRecoilState(showMoneyDialogState);

  const { getWallet } = useAuth();
  const [loading, setLoading] = useBoolean();

  const handleSubmit = async () => {
    getWallet();
    setShowMoneyDialog({ open: false });
  };

  return (
    <Dialog
      open={showMoneyDialog.open}
      onClose={handleSubmit}
      PaperProps={{ sx: { bgcolor: 'transparent', boxShadow: 'none' } }}
    >
      <Box>
        <Box
          bgcolor="background.paper"
          py={2}
          px={2}
          sx={{
            minWidth: '250px',
          }}
          borderRadius="16px"
        >
          <Box
            sx={{
              overflowX: 'scroll',
              width: '100%',
              maxWidth: '100%',
              ...hideScrollbarY,
            }}
            mt={'16px'}
          >
            <Stack
              direction="row"
              spacing="10px"
              sx={{
                justifyContent: 'center',
              }}
            >
              <Box>
                <Center>
                  <img
                    src={showMoneyDialog?.pic}
                    alt=""
                    style={{
                      width: '120px',
                      minWidth: '120px',
                      height: '120px',
                      borderRadius: '16px',
                    }}
                  />
                </Center>
                <Typography
                  mt={1}
                  textAlign="center"
                  variant="body1"
                  fontWeight={500}
                >
                  {`${
                    (lang === LanguageType.EN
                      ? showMoneyDialog?.contents?.enUS
                      : showMoneyDialog?.contents?.ptBR) ||
                    showMoneyDialog?.contents?.zhCN ||
                    ''
                  }`}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <LoadingButton
            variant="contained"
            fullWidth
            sx={{
              boxShadow: 'none !important',
              mt: 2,
              borderRadius: '8px',
            }}
            style={{
              backgroundColor: ColorfulBg(),
            }}
            loading={loading}
            onClick={handleSubmit}
          >
            {__('Receive')}
          </LoadingButton>
        </Box>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <IconButton size="small" onClick={handleSubmit}>
            <img
              src={ICON_CLOSE}
              alt=""
              style={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MoneyDialog;
