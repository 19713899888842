import useLang from '@/hooks/useLang';
import { inviteActivityState } from '@/stores/invite';
import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

export const StatisticsCard = () => {
  const info = useRecoilValue(inviteActivityState);
  const { __ } = useLang();

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '6px',
        py: 1,
        background: 'linear-gradient(180deg, #36A8F4 0%, #18457C 100%)',
      }}
    >
      <Box
        sx={{
          width: '50%',
          height: '100%',
          borderRight: '1px solid rgba(255, 255, 255, 0.15)',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1">
          {info?.userActivity?.completeRules?.[0].currentValue ?? 0}
        </Typography>
        <Box height={32} display="flex" alignItems="center">
          <Typography variant="body1" lineHeight={1} color="text.secondary">
            {__('Completed invitation conditions')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '50%',
          height: '100%',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1">
          <span
            style={{
              fontSize: '20px',
              fontWeight: 400,
              color: 'rgba(255, 255, 255, 0.65)',
              marginRight: '4px',
            }}
          >
            R$
          </span>
          {info?.userActivity?.receivedAmt ?? 0}
        </Typography>
        <Box height={32} display="flex" alignItems="center">
          <Typography variant="body1" lineHeight={1} color="text.secondary">
            {__('Bounty obtained')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
