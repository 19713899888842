import { AgentTotalDto } from '@/hooks/useAgentList';
import useLang from '@/hooks/useLang';
import { Box, Typography } from '@mui/material';

export const NewPlayersTotalInfoCard = (props: {
  data: AgentTotalDto | null;
}) => {
  const { __ } = useLang();
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '6px',
        py: 1,
        mb: 1.5,
        background: 'linear-gradient(180deg, #36A8F4 0%, #18457C 100%)',
      }}
    >
      <Box
        sx={{
          width: '50%',
          height: '100%',
          borderRight: '1px solid rgba(255, 255, 255, 0.15)',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1">{props.data?.all_num ?? 0}</Typography>
        <Box height={32} display="flex" alignItems="center">
          <Typography variant="body1" lineHeight={1} color="text.secondary">
            {__('Numbers of new members')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '50%',
          height: '100%',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1">{props.data?.yes_num ?? 0}</Typography>
        <Box height={32} display="flex" alignItems="center">
          <Typography variant="body1" lineHeight={1} color="text.secondary">
            {__('Valid direct invitations')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
