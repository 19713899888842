// import HEADICON from '@/assets/icons/home_t_d.gif';
import { showIOSDownLoadState } from '@/stores/auth';
import { supportListState } from '@/stores/config';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

// import HEADICON from '@/assets/icons/icon_download.svg';
import useLang from '@/hooks/useLang';
import { footerbarHeight } from '@/theme';
import { Box } from '@mui/material';
import { useState } from 'react';

interface Props {}

const AppDownload: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const [supportList, setSupportList] = useRecoilState(supportListState);
  const setShowIOSDownLoadState = useSetRecoilState(showIOSDownLoadState);
  const [standAlone, setStandAlone] = useState<boolean>(false);

  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  let liveSupport = supportList.find((su) => su.type === 6);
  const handleDl = async () => {
    if (liveSupport?.type == 6 && isIOS) {
      setShowIOSDownLoadState(true);
      return;
    }
    if (liveSupport?.url) {
      window.open(liveSupport.url, '_blank');
    }
  };

  function isStandalone() {
    //alert("ios:"+navigator.standalone+";  Android:"+(window.matchMedia('(display-mode: standalone)').matches)+";  Android:"+(window.matchMedia('(display-mode: fullscreen)').matches))
    return (
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.matchMedia('(display-mode: standalone)').matches
    );
  }

  useEffect(() => {
    if (isStandalone()) {
      setStandAlone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (standAlone) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: footerbarHeight + 22,
        left: '50%',
        zIndex: (theme) => theme.zIndex.drawer - 1,
      }}
    >
      <Box
        onClick={handleDl}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: 999,
          fontSize: 14,
          lineHeight: 1,
          height: 38,
          fontWeight: 500,
          fontStyle: 'italic',
          backdropFilter: 'blur(4px)',
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), rgba(26, 25, 25, 0.50)',
          transform: 'translateX(-50%)',
          boxShadow: 'none',
          border: 'none',
          color: 'rgba(241, 238, 134, 0.75)',
        }}
      >
        {__('Download APP')}
      </Box>
    </Box>
  );
};

export default AppDownload;
