import useLang from '@/hooks/useLang';
import services from '@/services';
import { agentLevelState } from '@/stores/auth';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { CommissionsRulesTable2 } from '../Tabs/CommissionsRules';
import IMAGE_FLOW from '../Tabs/images/invite-flow.png';

interface Props {}
export const WinBoxTab = (props: Props) => {
  const [agentLevelList, setAgentLevelList] = useRecoilState(agentLevelState);
  const { __ } = useLang();

  useEffect(() => {
    const fetchData = async () => {
      let data = await services.config.getAgentLevelInfo({
        sid: '1',
      });
      // let agentList = data?.data || [];
      // let nowAgent = agentList.find(
      //   (a: { is_user_agent: number }) => a.is_user_agent == 1,
      // );

      // if (nowAgent) {
      //   setRentouMoney(`${nowAgent.rentou_money}`);
      // }

      setAgentLevelList(data?.data || []);
    };

    fetchData();
  }, []);

  return (
    <Box px={1.5} pb={2} pt={{ xs: 0, md: 2 }}>
      <Box
        sx={{
          bgcolor: '#313D4D',
          borderRadius: 1,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={IMAGE_FLOW} alt="" width={249} height={246} />
        <Typography
          variant="body1"
          mt={3}
          color="text.secondary"
          fontWeight={500}
          fontStyle="italic"
        >
          {__(
            'For every new active member you invite, you get a percentage of the bonus.',
          )}
        </Typography>
      </Box>
      <Box mt={1.5}>
        <CommissionsRulesTable2 ruleList={agentLevelList} />
      </Box>
    </Box>
  );
};
