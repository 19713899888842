import AVATAR_DETAULT from '@/assets/images/avatar-default.png';
import { useAuthJump } from '@/hooks/useAuthJump';
import useLang from '@/hooks/useLang';
import { msgState } from '@/stores/msg';
import { I18nValue, MessageShowLevel } from '@/types';
import { Box, Typography } from '@mui/material';
import confetti from 'canvas-confetti';
import { memo, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import './index.css';

import Bg from '@/assets/images/heart-msg-bg.png';
import { globalMaxWidth } from '@/theme';

export const HeartbeatMsg = (props: { showLevel: MessageShowLevel }) => {
  const [msg, setMsg] = useRecoilState(msgState);
  const { authJump } = useAuthJump();

  const handleJump = () => {
    if (msg.message?.routePath) {
      authJump({
        url: msg.message.routePath,
        target: 2,
        pic: '',
        title: '',
        group_id: 0,
      });
    }
  };

  const handleClose = () => {
    setMsg({
      message: null,
    });
  };

  if (!msg.message) {
    return null;
  }

  if (msg.message.showLevel === props.showLevel) {
    return (
      <HeartbeatMsgBar
        pic={msg.message.pic}
        contents={msg.message.contents}
        routePath={msg.message.routePath}
        onClose={handleClose}
        onJump={handleJump}
      />
    );
  }

  return null;
};

interface Props {
  contents: I18nValue;
  pic?: string;
  routePath?: string;
  onClose: () => void;
  onJump: () => void;
}
export const HeartbeatMsgBar = memo((props: Props) => {
  const { getValueByLanguage } = useLang();

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.onClose();
    }, 7_800);

    const timeout2 = setTimeout(() => {
      const msgBar = document.getElementById('heartbeat-msg-bar');
      if (msgBar) {
        msgBar.classList.remove('fade-in-top');
        msgBar.classList.add('fade-out-top');
      }
    }, 7_000);

    var end = Date.now() + 3 * 1000;

    let fireOriginX = 0;
    if (window.innerWidth > globalMaxWidth) {
      fireOriginX =
        (window.innerWidth - globalMaxWidth) / 2 / window.innerWidth;
    }

    // go Buckeyes!
    var colors = ['#bb0000', '#ffffff'];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 50,
        spread: 30,
        gravity: 0.5,
        origin: { x: fireOriginX, y: 0.3 },
        colors: colors,
        zIndex: 1102,
      });
      confetti({
        particleCount: 2,
        angle: 130,
        spread: 30,
        gravity: 0.5,
        origin: { x: 1 - fireOriginX, y: 0.3 },
        colors: colors,
        zIndex: 1102,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();

    const audioEle = document.getElementById(
      'reward-audio',
    ) as HTMLAudioElement;
    if (audioEle) {
      audioEle.play();
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        right: 0,
        zIndex: (theme) => theme.zIndex.appBar + 1,
        width: '100%',
        maxWidth: globalMaxWidth,
        display: 'flex',
        justifyContent: 'center',
      }}
      className="fade-in-top"
      id="heartbeat-msg-bar"
    >
      <Box
        sx={{
          width: '100%',
          height: '240px',
          backgroundImage: `url(${Bg})`,
          backgroundSize: '100% 240px',
        }}
      >
        <Box>
          <Typography
            onClick={props.onJump}
            sx={{
              flex: 'auto',
              minWidth: 0,
              pr: 1,
              textAlign: 'center',
              mt: '90px',
              fontSize: 16,
              maxWidth: '70%',
              wordBreak: 'break-word',
              whitespace: 'wrap',
              lineHeight: 1,
              mx: 'auto',
            }}
            fontWeight={500}
            fontStyle="italic"
          >
            {getValueByLanguage(props.contents)}
          </Typography>
        </Box>
      </Box>

      {/* <Box bgcolor="#2B2D33" borderRadius={0.5} overflow="hidden">
        <Box
          sx={{
            border: '0.5px solid',
            borderRadius: 0.5,
            p: 1,
            pr: 0.5,
            borderColor: 'primary.main',
            background:
              'linear-gradient(89.56deg, rgba(229, 140, 219, 0.375) 0.77%, rgba(229, 140, 219, 0.125) 99.23%)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '28px',
                height: '28px',
                minWidth: '28px',
                borderRadius: '50%',
                overflow: 'hidden',
                mr: 1.5,
              }}
              onClick={props.onJump}
            >
              <Avatar pic={props.pic || ''} />
            </Box>

            <Typography
              onClick={props.onJump}
              sx={{
                flex: 'auto',
                minWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                pr: 1,
              }}
              variant="body1"
              fontWeight={400}
              fontStyle="italic"
            >
              {getValueByLanguage(props.contents)}
            </Typography>

            <Box
              sx={{
                width: '24px',
                height: '24px',
                minWidth: '24px',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={props.onClose}
            >
              <CloseRoundedIcon />
            </Box>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
});

const Avatar = (props: { pic: string }) => {
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  return (
    <img
      src={isError ? AVATAR_DETAULT : props.pic}
      alt=""
      width={28}
      height={28}
      onError={handleError}
    />
  );
};
