import services from '@/services';
import { IDepositCoupon } from '@/types/config';
import { useEffect, useState } from 'react';

export const useDepositCoupons = () => {
  const [coupons, setCoupons] = useState<IDepositCoupon[]>([]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    const data = await services.config.getRechargeCoupon();
    setCoupons(Array.isArray(data) ? data : []);
  };

  return {
    coupons,
    fetchCoupons,
  };
};
