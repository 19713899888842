import FIRE from '@/assets/icons/fire.png';
import useLang from '@/hooks/useLang';
import { depositTabState } from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';

export const DepositTabs = () => {
  const { __ } = useLang();
  const [tab, setTab] = useRecoilState(depositTabState);
  return (
    <Box
      sx={{
        height: '40px',
        my: 1.5,
        position: 'sticky',
        zIndex: 100,
        top: 50,
        left: 0,
        pl: 0,
        pr: 0,
        mx: '16px',
        display: 'flex',
        bgcolor: 'background.default',
        borderRadius: '8px',
      }}
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('amount');
        }}
        sx={{
          bgcolor: tab === 'amount' ? '#3D333C' : '',
          border: tab === 'amount' ? `1px solid ${ColorfulBg()}` : '',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={tab === 'amount' ? 700 : 500}
          lineHeight={1}
          sx={{ textAlign: 'center' }}
          color={tab === 'amount' ? 'text.primary' : 'text.secondary'}
        >
          {__('Amount Recharge')}
        </Typography>
      </Box>
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        onClick={() => {
          setTab('package');
        }}
        sx={{
          background:
            tab !== 'package'
              ? 'linear-gradient(96.29deg, rgba(229, 140, 219, 0) 39.61%, rgba(229, 140, 219, 0.5) 104.1%)'
              : ColorfulBg(),
          // bgcolor: tab === 'package' ? 'background.neutral' : '',
          // border: tab === 'package' ? `1px solid ${ColorfulBg()}` : '',
          borderRadius: '8px',
          cursor: 'pointer',
          position: 'relative',
          px: 1.5,
          textAlign: 'left',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={tab === 'package' ? 700 : 500}
          lineHeight={1}
          color={tab === 'package' ? 'text.primary' : 'text.secondary'}
        >
          {__('Package Purchase')}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            width: '48px',
            height: '48px',
            bottom: 6,
          }}
        >
          <img src={FIRE} alt="" width={48} height={48} />
        </Box>
      </Box>
    </Box>
  );
};
