import { Box } from '@mui/material';

export const VipTag = ({ src }: { src: string }) => {
  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        borderRadius: '0px 8px 0px 8px',
        bgcolor: 'card.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >
      <img src={src} alt="" style={{ width: 16, height: 16 }} />
    </Box>
  );
};
