import useLang from '@/hooks/useLang';
import { inviteActivityState } from '@/stores/invite';
import { LanguageType } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

export const RuleDescription = () => {
  const { __, language } = useLang();
  // const agentLevelList = useRecoilValue(agentLevelState);
  // const minMoney =
  //   agentLevelList?.length > 0 ? agentLevelList[0].rentou_money : 0;
  // const maxMoney =
  //   agentLevelList?.length > 1
  //     ? agentLevelList[agentLevelList.length - 1].rentou_money
  //     : 0;

  // const { loading, detail, boxes, refetchDetail, eventInfo } = useActivity(
  //   ActivityType.邀新活动,
  // );
  const detail = useRecoilValue(inviteActivityState);
  const isEn = language === LanguageType.EN;

  let rule =
    (isEn
      ? detail?.blurb?.enUS.split('\\n')
      : detail?.blurb?.ptBR.split('\\n')) || [];

  return (
    <Box p={2}>
      <Typography
        variant="subtitle2"
        lineHeight={1}
        pb={1}
        fontWeight={500}
        fontStyle="italic"
      >
        {__('Rule description')}
      </Typography>
      <Box p={2} borderRadius={1} bgcolor="#212933">
        <Stack spacing={1}>
          {rule?.map((str, index) => {
            return (
              <Typography
                key={index}
                variant="body1"
                color="text.secondary"
                fontStyle="italic"
                lineHeight={1.5}
                fontWeight={500}
              >
                {str}
              </Typography>
            );
          })}
        </Stack>
        {/* <Typography variant="body1" color="text.secondary">
          {__('commission tip1')
            .replace('[min]', minMoney)
            .replace('[max]', maxMoney)}
          <br />
          {__('commission tip2')}
          <br />
          {__('commission tip3')}
        </Typography> */}
      </Box>
    </Box>
  );
};
