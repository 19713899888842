import { useEffect, useState } from "react";
import services from "../services";
import { IMyXp } from "../types/user";

const useMyXp = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [myXp, setMyXp] = useState<IMyXp | null>(null);

  useEffect(() => {
    const fetchOwnBox = async () => {
      try {
        setLoading(true);
        const { data } = await services.auth.getMyXp();
        setMyXp(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchOwnBox();
  }, []);
  return {
    loading,
    myXp,
  };
};

export default useMyXp;
