import { Box, Typography } from "@mui/material";
import CommonSearchPage from "./CommonSearchPage";

interface Props {}

const GameListLiveCasino: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <Typography mb="30px" textAlign="center" variant="h2">
        Live Casino
      </Typography>
      <CommonSearchPage category="live-casino" />
    </Box>
  );
};

export default GameListLiveCasino;
